import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "scenarioForm",
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
  },

  data() {
    return {
      scenario: {
        title: "",
        description: "",
      },
      mode: "",
      url: "/admin/scenarios",
      disabled: false,
    };
  },
  mounted() {
    this.mode = this.$route.params.id ? "edit" : "create";
    this.url =
      this.mode === "edit" ? this.url + "/" + this.$route.params.id : this.url;
    if (this.mode === "edit") {
      this.$axios.get(this.url).then((res) => {
        this.scenario = res.data.data.scenario;
      });
    }
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData(e.target);
        if (this.mode == "edit") {
          formData.append("_method", "PATCH");
        }
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/scenario");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
