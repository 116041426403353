<template>
  <form @submit.prevent="submit">
    <div class="row">
 
      <div class="col-xl-8 col-md-12">
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">اطلاعات اولیه</h5>
          </div>
          <div class="card-body">
            <b-form-group label="عنوان">
              <b-form-input
                v-model="tournaments.title"
                name="title"
                type="text"
                placeholder="عنوان"
              />
            </b-form-group>
            <b-form-group label="تاریخ شروع تورنومنت">
              <date-picker
                name="start_date"
                type="date"
                v-model="tournaments.start_date"
                format="YYYY-MM-DD"
                display-format="jYYYY-jMM-jDD"
              />
            </b-form-group>
            <b-form-group label="حداکثر گنجایش نفرات">
              <b-form-input
                v-model="tournaments.max_capacity"
                type="text"
                name="max_capacity"
                placeholder="تعداد صندلی"
              />
            </b-form-group>
            
            <b-form-group label="هزینه رزرو صندلی">
              <b-form-input
                v-model="tournaments.price"
                name="price"
                type="text"
                placeholder="قیمت به تومان"
              />
            </b-form-group>
            <b-form-group label="تصویر اصلی رویداد">
              <b-form-file
                v-model="tournaments.main_image"
                name="main_image"
                type="text"
                placeholder="تصویر"
              />
            </b-form-group>
            <b-form-group label="توضیحات">
              <b-form-textarea
                v-model="tournaments.description"
                name="description"
                type="text"
                placeholder="توضیحات"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-12">
   
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">تصاویر</h5>
          </div>
          <div class="card-body">
            <b-form-file
              v-model="image1"
              placeholder="تصویره شماره 1"
            ></b-form-file>
            <b-form-file
              v-model="image2"
              placeholder="تصویره شماره 2"
            ></b-form-file>
            <b-form-file
              v-model="image3"
              placeholder="تصویره شماره 3"
            ></b-form-file>
            
            <div class="text-center mt-5">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-success"
              >
                {{ mode == "edit" ? " ویرایش تورنومنت" : " ثبت تورنومنت" }}
              </button>
            </div>
          </div>
        </div>
        
      </div>

      <!-- <div class="col-md-12">
        <b-form-group label="توضیح">
          <b-form-textarea
            v-model="tournaments.description"
            name="description"
          ></b-form-textarea>
        </b-form-group>
      </div> -->
    </div>
  </form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import tournamentFormImpl from "@@/core/components/tournament/tournamentForm/tournamentFormImpl";
export default {
  components: {
    Treeselect,
  },
  extends: tournamentFormImpl,
};
</script>

<style scoped></style>
