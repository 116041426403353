<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link :disabled="hasPermission('write_category') ? false : true" to="/admin/categories/create"
        @click="createItem" class="btn btn-success">دسته بندی جدید</router-link>
    </span>
    <b-tooltip v-if="!hasPermission('write_category')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">دسته بندی محصولات</h5>
        <div style="flex:1"></div>
        <b-button v-if="change" @click="sortCategory" :disabled="disabled" variant="info">
          اعمال تغییرات
        </b-button>
      </div>
      <div class="card-body">
        <div>
          <template v-if="items != null">
            <VueNestable v-model="items" rtl @change="onChange">
              <VueNestableHandle slot-scope="{ item }" :item="item">
                <span style="user-select: none;"> <i class="fa fa-arrows-v" style="font-size:11px"></i> {{ item.id + ' - ' + item.title }}</span>
                <span :id="'edit-btn-' + item.id">
                  <router-link
                    :to="{name:'category.edit' , params:{id: item.id}}" :disabled="disabled || !hasPermission('modify_category') ? true : false "
                    class="action-btns1 px-2 mr-2">
                    <i class="feather feather-edit  text-primary" style="font-size: 14px;"></i>
                  </router-link>
                </span>
                <b-tooltip v-if="!hasPermission('modify_category')" :target="'edit-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>

              
                <span :id="'delete-btn-' + item.id">
                  <a :disabled="hasPermission('delete_category') ? false : true " class="action-btns1 px-2 mr-1" @click="deleteItem(item.id)">
                    <i class="feather feather-trash-2 text-danger" style="font-size: 14px;"></i>
                  </a>
                </span>
                <b-tooltip v-if="!hasPermission('delete_category')" :target="'delete-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </VueNestableHandle>
            </VueNestable>
          </template>
          <!-- <VueNestable v-model="items"></VueNestable> -->
          <Loading v-if="items == null" />
          <div v-if="items == ''" class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import CategoryImpl from '@@/core/pages/category/Category/CategoryImpl'
export default {
  extends: CategoryImpl
}
</script>

<style src="@@/core/pages/category/Category/CategoryStylePublic.css"></style>