<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش گالری" : "گالری جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back ref="back" />
      </div>
      <div class="card-body">
        <galleryForm ref="galleryForm" />
      </div>
    </div>
  </main>
</template>
<script>
import galleryCreateEditImpl from "@@/core/pages/gallery/galleryCreateEdit/galleryCreateEditImpl";
export default {
  extends: galleryCreateEditImpl,
};
</script>
