import Back from '@@/core/components/shared/Back/Back'
import scenarioForm from '@@/core/components/scenario/scenarioForm/scenarioForm'

    export default {
        components: {
            Back,
            scenarioForm
        },
       
    }
