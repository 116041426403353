import ImageHolder from "@@/core/components/shared/ImageHolder/ImageHolder";
import { BButton } from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  name: "ProductImages",
  components: { ImageHolder, BButton, draggable },
  props: {
    value: Array,
    hideShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      realValue: this.value,
      selectedImages: [],
    };
  },
  watch: {
    value: function (newVal) {
      this.realValue = newVal;
    },
    realValue: function (newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    window.addEventListener("click", this.outsideClicked);
  },
  methods: {
    outsideClicked(e) {
      if (!this.$root.hasClass(e.target, "product-image-cell")) {
        this.selectedImages = [];
      }
    },
    fileAdded(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.addImage(e.target.files[i]);
      }
    },
    dropFile(e) {
      this.isDragging = false;
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (
          e.dataTransfer.items[i].kind === "file" &&
          e.dataTransfer.items[i].type.includes("image")
        ) {
          let file = e.dataTransfer.items[i].getAsFile();
          this.addImage(file);
        }
      }
    },
    addImage(_file) {
      if (!_file.type.includes("image")) {
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = (file) => {
        const srcBase64 = file.target.result;
        const image = {
          url: srcBase64,
          key: this.$root.uniqueId(),
        };
        this.realValue.push(image);
        this.$refs.fileInput.value = "";
      };
    },
    deleteImage(key) {
      this.realValue = this.realValue.filter((img) => {
        return img.key !== key;
      });
      this.selectedImages = this.selectedImages.filter((_key) => key != _key);
    },
    imageClicked(e, imageKey) {
      if (["I", "i", "button", "BUTTON"].includes(e.target.tagName)) {
        return;
      }
      if (e.shiftKey) {
        if (this.selectedImages.length) {
          let selectedIndex = 0;
          this.realValue.forEach((img, index) => {
            if (img.key === imageKey) {
              selectedIndex = index;
            }
          });
          let rightOffset = 99999999;
          let rightIndex = -1;
          for (let i = selectedIndex + 1; i <= this.realValue.length; i++) {
            if (this.realValue[i]) {
              if (this.selectedImages.includes(this.realValue[i].key)) {
                rightIndex = i;
                rightOffset = i - selectedIndex;
                break;
              }
            }
          }
          let leftOffset = 999999999;
          let leftIndex = -1;
          for (let i = selectedIndex - 1; i >= 0; i--) {
            if (this.realValue[i]) {
              if (this.selectedImages.includes(this.realValue[i].key)) {
                leftIndex = i;
                leftOffset = i - selectedIndex;
                break;
              }
            }
          }
          let targetIndex = leftOffset < rightOffset ? leftIndex : rightIndex;
          let l = Math.min(targetIndex, selectedIndex);
          let r = Math.max(targetIndex, selectedIndex);
          let finalSelectedImages = [];
          for (let i = l; i <= r; i++) {
            if (this.realValue[i]) {
              finalSelectedImages.push(this.realValue[i].key);
            }
          }
          this.selectedImages = finalSelectedImages;
        }

        return;
      }
      if (e.ctrlKey) {
        if (this.selectedImages.includes(imageKey)) {
          this.selectedImages = this.selectedImages.filter((img) => {
            return img !== imageKey;
          });
        } else {
          this.selectedImages.push(imageKey);
        }
      } else {
        if (
          this.selectedImages.includes(imageKey) &&
          this.selectedImages.length === 1
        ) {
          this.selectedImages = [];
        } else {
          this.selectedImages = [imageKey];
        }
      }
    },
    prioritizeSelectedImage() {
      let imageKey = this.selectedImages[0];
      let imageSrc = "";
      let copyRealValue = this.realValue;
      copyRealValue = copyRealValue.filter((img) => {
        if (img.key === imageKey) {
          imageSrc = img.url;
          return false;
        }
        return true;
      });
      copyRealValue.unshift({
        key: imageKey,
        url: imageSrc,
      });

      this.realValue = copyRealValue;
      this.selectedImages = [];
    },
    removeSelectedImages() {
      this.realValue = this.realValue.filter((img) => {
        return !this.selectedImages.includes(img.key);
      });
      this.selectedImages = [];
    },
    addDragAnimation(e) {
      // Check if drag is not for file
      if (e.dataTransfer.effectAllowed === "move") {
        return;
      }
      this.isDragging = true;
    },
    removeDragAnimation() {
      this.isDragging = false;
    },
  },
};
