import Loading from "@@/core/components/shared/Loading/Loading";
import Back from "@@/core/components/shared/Back/Back";
import mixins from "@@/core/mixins/mixins";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import { Collapse } from "bootstrap";
export default {
  name: "registerUsers",
  mixins: [mixins],
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    Back,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      chairs: [],
      selectedChair: "",
      usersList: [],
      lastQ: "",
      mainData: null,
      url: "admin/events",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "fullName",
          label: "نام کامل",
        },
        {
          key: "chair_number",
          label: "شماره صندلی",
        },
        {
          key: "sum_scores",
          label: "جمع امتیازات",
        },
        {
          key: "grade",
          label: "رتبه",
        },
        {
          key: "count_joined_tournaments",
          label: "تورنومنت",
        },
      ],
      selectedUser: "",
      disabled: false,
    };
  },
  mounted() {
    if (this.$route.query.add) {
      new Collapse(this.$refs.menuCollapse).toggle();
    }
    this.loadData();
  },
  methods: {
    selectedChairFunc(id) {
      this.selectedChair = id;
    },
    registerUser() {
      //        //
      let fd = new FormData();
      fd.append("chair_id", this.selectedChair);
      fd.append("user_id", this.selectedUser.id);
      this.disabled = true;
      this.$axios
        .post(`admin/register_user/${this.$route.params.id}`, fd)
        .then((res) => {
          this.loadData();
          this.disabled = false;
          this.$root.notify(res.data.message, "success");
          this.selectedChair = "";
        })
        .catch((errr) => {
          this.disabled = false;

          this.$root.notify(errr);
        });
    },
    loadData() {
      this.$axios
        .get(`admin/events/${this.$route.params.id}`)
        .then((res) => {
          this.mainData = res.data.data.eventGame;
          this.chairs = res.data.data.eventGame.chairs.filter((chair) => {
            return chair.is_reserved == 0;
          });
        })
        .catch((error) => {
          this.$root.notify(error);
          this.$router.push("/admin/events");
        });
    },
    async getUser(q) {
      if (this.lastQ == q || q == "" || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel();
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        const response = await this.$axios.get(
          `admin/search_user?search=${q}`,
          {
            cancelToken: this.axiosProductsCancelSource.token,
          }
        );
        this.usersList = response.data.data.users.map((c) => {
          c.label = c.id + " - " + c.mobile;
          if (c.first_name || c.last_name) {
            c.label += " - ";
          }
          if (c.first_name) {
            c.label += c.first_name;
          }
          if (c.last_name) {
            c.label += c.last_name;
          }
          return c;
        });
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e);
        }
      }
    },
  },
};
