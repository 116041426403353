import {BTooltip, BFormRating, BModal} from "bootstrap-vue";

export default {
  components: {
    BTooltip, BFormRating, BModal
  },
  props: {
    comment: Object,
    url: {
      default: 'admin/product-comment',
      type: String
    }
  },
  data() {
    return {
      disabled: false
    }
  },
  methods: {
    async changeStatus(status) {
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url, {
          id: this.comment.id,
          status: status
        })

        if (this.$parent._data.items && this.$parent._data.items.data) {
          this.$parent._data.items.data[this.comment.index].status = status
        }
        this.$emit('statusChanged', {id: this.comment.id, status})
        this.$root.notify(response.data.message, 'success')
        this.$root.$emit('bv::hide::modal', 'comment-modal')
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    }
  }
}
