import Sidebar from '@@/core/components/shared/Sidebar/Sidebar'
import Footer from '@@/core/components/shared/Footer/Footer'
import Header from '@@/core/components/shared/Header/Header'
import Notifications from '@@/core/components/shared/Notifications/Notifications'
import mixins from '@@/core/mixins/mixins'
import adminAssets from '@@/core/mixins/adminAssets'
import Gallery from '@@/core/other-components/gallery/components/Gallery'


export default {
  name: "Index",
  components: {
    Sidebar,
    Footer,
    Header,
    Notifications,
    Gallery
  },
  mixins: [ mixins, adminAssets],
  data() {
    return {
      password: null,
      confirmPassword: null,
      oldPassword: null,
      images: [] // For gallery
    }
  },
  created() {
    this.$root.$on('openGallery', (payload) => {
      let { image, index } = payload
      console.log(image)
      console.log(index)
      let sanitize = (img) => ({...img, src: img.url})
      if (Array.isArray(image)) {
        image = image.map(i => sanitize(i))
        let img = image[index]
        this.images = image;
        this.$refs.gallery.openOverlay(img, index)
      } else {
        this.images = [];
        this.$refs.gallery.openOverlay(sanitize(image))
      }
    });

  },
  mounted() {
    // this.$store.dispatch('admin/loadInitData')
  },
  methods: {
    async changePassword() {
      this.disabled = true
      try{
        const response = await this.$axios.put('/admin/password' , {
          old_password: this.oldPassword,
          password: this.password,
          password_confirmation: this.confirmPassword,
        })
        this.$root.notify(response.data.messsage, 'success')
        this.$router.push("/admin/login")
      } catch(error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
    sidebarStatus() {
      return this.$root.getCookie('sidebarStatus') == 'close' ? 'sidenav-toggled' : '';
    }
  }
}
