<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش مطلب" : "مطلب جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back ref="back" />
      </div>
      <div class="card-body">
        <b-form-group label="عنوان">
          <b-form-file v-model="blog.title"></b-form-file>
        </b-form-group>
        <b-form-group label="وضعیت">
          <b-form-checkbox v-model="blog.status"></b-form-checkbox>
        </b-form-group>
        <b-form-group label="توضیحات">
          <textarea
            id="editor"
            ref="editor"
            type="text"
            name="editor"
            :value="blog.body"
          />
        </b-form-group>
      </div>
    </div>
  </main>
</template>
<script>
import PostCreateEditImpl from "@@/core/pages/post/PostCreateEdit/PostCreateEditImpl";
export default {
  extends: PostCreateEditImpl,
};
</script>
