import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import eventImages from "@@/core/components/events/eventImages/eventImages";
export default {
  name: "eventsForm",
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    eventImages,
  },
  props: {
    selectDefaultHall: String,
  },
  data() {
    return {
      image1: "",
      image2: "",
      image3: "",
      lastQ: "",
      usersList: [],
      events: {
        title: "",
        description: "",
        event_place: "",
        event_address: "",
        event_date: null,
        chair_price: "",
        event_god_id: "",
        event_time: "",
        chair_price: 0,
        max_chairs_capacity: 1,
        scenario_id: null,
        chairs_design: "null",
        event_type: { id: "normal", name: "معمولی" },
        status: "",
        event_referee_id: [],
      },
      hideEventType: false,
      scenario_ids: [],
      max_chairs_capacity: null,
      hall: { id: "22", name: "سالن A" },
      halls: [
        { id: "22", name: "سالن A" },
        { id: "15", name: "سالن B" },
        { id: "13", name: "سالن C" },
      ],
      chairs_designs: [
        { id: "circle", name: "دایره" },
        // { id: "square", name: "مربع" },
        { id: "rectangle", name: "مستطیل" },
        // { id: "triangle", name: "مثلث" },
        // { id: "double_line", name: "دوخطی" },
      ],
      event_types: [
        { id: "normal", name: "معمولی" },
        // { id: "special", name: "ویژه" },
        { id: "tournament", name: "تورنومنت" },
      ],
      statuses: [
        { id: "reserved", name: "رزرو" },
        { id: "registering", name: "ثبت نام" },
        { id: "in_progress", name: "در حال پردازش" },
        { id: "finished", name: "تموم شده" },
      ],
      main_image: "",
      mode: "",
      url: "/admin/events",
      disabled: false,
    };
  },
  mounted() {
    this.mode = this.$route.params.id ? "edit" : "create";
    this.url =
      this.mode === "edit" ? this.url + "/" + this.$route.params.id : this.url;
    if (this.mode === "edit") {
      this.$axios.get(this.url).then((res) => {
        let eg = res.data.data.eventGame;
        eg.event_type = this.event_types.find((et) => et.id == eg.event_type);
        eg.status = this.statuses.find((e) => e.id == eg.status);
        eg.chairs_design = this.chairs_designs.find(
          (e) => e.id == eg.chairs_design
        );
        eg.scenario_id = eg.scenario;
        this.events = eg;
      });
    }
    this.$axios.get("admin/events/create").then((res) => {
      this.scenario_ids = res.data.data.scenario;
    });
    if (this.$route.query.for && this.$route.query.for == "tournament") {
      this.events.event_type = { id: "tournament", name: "تورنومنت" };
      this.hideEventType = true;
    }
  },

  methods: {
    selectDefaultValues(Flag) {},
    async getUser(q) {
      if (this.lastQ == q || q == "" || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel();
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        const response = await this.$axios.get(
          `admin/search_user?search=${q}`,
          {
            cancelToken: this.axiosProductsCancelSource.token,
          }
        );
        this.usersList = response.data.data.users.map((c) => {
          c.label = c.id + " - " + c.mobile;
          if (c.first_name || c.last_name) {
            c.label += " - ";
          }
          if (c.first_name) {
            c.label += c.first_name;
          }
          if (c.last_name) {
            c.label += c.last_name;
          }
          return c;
        });
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e);
        }
      }
    },
    async getUserForGod(q) {
      if (this.lastQ == q || q == "" || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel();
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        const response = await this.$axios.get(
          `admin/search_user?search=${q}`,
          {
            cancelToken: this.axiosProductsCancelSource.token,
          }
        );
        this.usersList = response.data.data.users.map((c) => {
          c.label = c.id + " - " + c.mobile;
          if (c.first_name || c.last_name) {
            c.label += " - ";
          }
          if (c.first_name) {
            c.label += c.first_name;
          }
          if (c.last_name) {
            c.label += c.last_name;
          }
          return c;
        });
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e);
        }
      }
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        console.log(this.events.event_referee_id);
        let formData = new FormData(e.target);
        // if (this.events.event_referee_id.length) {
        // this.events.event_referee_id.forEach((user,index) => {
        // formData.append(`event_referee_admin_ids[]`,null);
        // formData.append(`event_referee_user_ids[]`,null);
        // });
        // }
        if (this.max_chairs_capacity) {
          if (Number(this.max_chairs_capacity) > Number(this.hall.id)) {
            throw {
              flag: true,
              message: "حداکثر گنجایش از تعداد صندلی های سالن بیشتر است",
            };
          } else {
            formData.append("max_threshold", this.max_chairs_capacity);
            formData.append("max_chairs_capacity", this.hall.id);
          }
        } else {
          formData.append("max_chairs_capacity", this.hall.id);
        }
        formData.append("chairs_design", "rectangle");
        formData.append("event_date", this.events.event_date);
        formData.append(
          "description",
          this.events.description
            ? this.events.description
            : "https://partmafia.com/blogs"
        );

        formData.append(
          "event_god_id",
          this.events.event_god_id ? this.events.event_god_id.id : null
        );
        formData.append(
          "event_type",
          this.events.event_type ? this.events.event_type.id : null
        );
        formData.append(
          "scenario_id",
          this.events.scenario_id ? this.events.scenario_id.id : null
        );

        formData.append(`images[0]`, this.image1);

        formData.append(`images[1]`, this.image2);

        formData.append(`images[2]`, this.image2);

        if (this.mode == "edit") {
          formData.append("_method", "PATCH");
        }
        const response = await this.$axios.post(
          this.events.event_type.id == "tournament"
            ? "/admin/events/tournaments"
            : this.url,
          formData
        );
        this.$root.notify(response, "success");
        this.$router.push("/admin/events");
      } catch (error) {
        if (error.flag) {
          this.$notify({
            group: "error",
            type: "error",
            type: "خطا",
            text: error.message,
          });
        } else {
          this.$root.notify(error);
        }
      } finally {
        this.disabled = false;
      }
    },
  },
  watch: {
    selectDefaultHall(newValue) {
      if (newValue == "A") {
        this.events = {
          title: "رویداد ",
          description: "",
          event_place: "سالن A",
          event_address: "بالای آیسپک",
          event_date: new Date()
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          chair_price: "",
          event_god_id: "",
          event_time: "",
          chair_price: 0,
          max_chairs_capacity: 22,
          scenario_id: null,
          chairs_design: "rectangle",
          event_type: this.hideEventType
            ? { id: "tournament", name: "تورنومنت" }
            :  { id: "normal", name: "معمولی" },
          status: "",
          event_referee_id: [],
        };
        this.hall = { id: "22", name: "سالن A" };
      } else if (newValue == "B") {
        // image1: "",
        // image2: "",
        // image3: "",
        // main_image: "",

        this.events = {
          title: "رویداد ",
          description: "",
          event_place: "سالن B",
          event_address: "بالای آیسپک",
          event_date: new Date()
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          chair_price: "",
          event_god_id: "",
          event_time: "",
          chair_price: 0,
          max_chairs_capacity: 15,
          scenario_id: null,
          chairs_design: "rectangle",

          event_type: this.hideEventType
            ? { id: "tournament", name: "تورنومنت" }
            :  { id: "normal", name: "معمولی" },
          status: "",
          event_referee_id: [],
        };
        this.hall = { id: "15", name: "سالن B" };
      } else {
        // image1: "",
        // image2: "",
        // image3: "",
        // main_image: "",

        this.events = {
          title: "رویداد ",
          description: "",
          event_place: "سالن C",
          event_address: "بالای آیسپک",
          event_date: new Date()
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          chair_price: "",
          event_god_id: "",
          event_time: "",
          chair_price: 0,
          max_chairs_capacity: 13,
          scenario_id: null,
          chairs_design: "rectangle",

          event_type: this.hideEventType
            ? { id: "tournament", name: "تورنومنت" }
            :  { id: "normal", name: "معمولی" },
          status: "",
          event_referee_id: [],
        };
        this.hall = { id: "13", name: "سالن C" };
      }
    },
  },
};
