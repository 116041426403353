import {getEventLabel, getModelLabel} from '@@/core/libraries/adminFunctions'

export default {
  name: "ActivityLog",
  props: {
    logs: Array
  },
  methods: {
    getEventLabel, getModelLabel
  }
}
