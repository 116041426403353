import Back from "@@/core/components/shared/Back/Back";
import {
  BTable,
  BForm,
  BFormGroup,
  BFormFile,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    Back,
    BForm,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },

        {
          key: "price",
          label: "قیمت",
        },
      ],
      games: [],
      selectedGames: null,
      disabled: false,
      festival: {
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        main_image: "",
      },
      mode: "create",
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadInitData();
    } else {
      this.loadGames();
    }
  },
  methods: {
    loadGames() {
      this.$axios.get("admin/games?all=1").then((res) => {
        this.games = res.data.data.games.data;
      });
    },
    sendFestival(e) {
      this.disabled = true;
      let fd = new FormData(document.forms["festivalForm"]);
      fd.append("title", this.festival.title);
      fd.append("description", this.festival.description);
      fd.append("start_date", this.festival.start_date);
      fd.append("end_date", this.festival.end_date);
      fd.append("main_image", this.festival.main_image);
      fd.append("status", "canceled");
      if (this.mode == "edit") {
        fd.append("_method", "PUT");
      }
      this.$axios
        .post(`admin/festivals${this.mode == "edit" ? "/" + this.$route.params.id : ""}`, fd)
        .then((res) => {
          this.disabled = false;

          this.$root.successNotif(res.data.message);
          this.$router.push("/admin/festival");
        })
        .catch((error) => {
          this.disabled = false;
          this.$root.notify(error);
        });
    },
    loadInitData() {
      this.mode = "edit";
      this.$axios
        .get(`admin/festivals/${this.$route.params.id}`)
        .then((res) => {
          this.festival = res.data.data.festival;
          this.$axios.get("admin/games?all=1").then((res2) => {
            this.games = res2.data.data.games.data;
            let list = [];
            res.data.data.festival.festival_games.forEach((game) => {
              let finded = this.games.find((item) => {
                return item.id == game.game_id;
              });
              if (finded) {
                finded.price = game.price;
                list.push(finded);
              }
            });
            this.selectedGames = list;
          });
        });
    },
  },
};
