import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import Swal from 'sweetalert2'
import {BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, VBToggle, VBTooltip} from "bootstrap-vue";

export default {
  name: "Products",
  mixins: [mixins],
  components: {
    Loading,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput
  },
  directives: {
    'b-toggle': VBToggle, 'b-tooltip': VBTooltip,
  },
  data() {
    return {
      url: 'admin/products',
      widgets: [
        'categories'
      ],
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'title',
          label: 'عنوان'
        },
        {
          key: 'main_price',
          label: 'قیمت'
        },
        {
          key: 'quantity',
          label: 'موجودی'
        },
        {
          key: 'categories',
          label: 'دسته بندی ها'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'approved_at',
          label: 'وضعیت تایید'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      showingCategories: [],
      statusOptions: [{
        value: 'available',
        name: 'موجود',
      },
        {
          value: 'soon',
          name: 'بزودی',
        },
        {
          value: 'out_of_stock',
          name: 'ناموجود',
        },
        {
          value: 'draft',
          name: 'پیش نویس',
        },
        {
          value: '',
          name: 'همه',
        },
      ],
      approveOptions: [{
        name: 'تایید شده',
        value: '1'
      },
        {
          name: 'تایید نشده',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
      filter: {
        id: '',
        title: '',
        category_id: '',
        status: '',
        approved_at: ''
      },
    }
  },
  mounted() {
    this.loadItems().then(response => {
      window.ok = response.data.data.statusCounts
      for (let [key, count] of Object.entries(response.data.data.statusCounts)) {
        this.statusOptions = this.statusOptions.map(statusOption => {
          if (statusOption.value == key) {
            statusOption.name = statusOption.name + ` (${count})`;
          }
          return statusOption
        })
      }
    })
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.statusOptions.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.approved_at': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.approved_at = this.approveOptions.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.category_id': function (newVal) {
      this.onLoadHandlers.push(() => {
        if (typeof newVal === 'string' || typeof newVal === 'number') {
          this.filter.category_id = this.widgetData.categories.find(item => {
            return item.id == newVal
          })
        }
      })
    },
  },
  methods: {
    showCat(p) {
      if (!this.showingCategories.includes(p)) {
        this.showingCategories.push(p)
      }
    },
    hideCat(p) {
      this.showingCategories = this.showingCategories.filter(_p => {
        return _p !== p;
      })
    },
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.id,
        searchBy1: 'id',
        search2: this.filter.title,
        searchBy2: 'title',
        search3: this.filter.category_id != null ? this.filter.category_id.id : '',
        searchBy3: 'category_id',
        search4: this.filter.status ? this.filter.status.value : '',
        searchBy4: 'status',
        search5: this.filter.approved_at ? this.filter.approved_at.value : '',
        searchBy5: 'approved_at',
      }
    },
    async approveForm(e, product) {
      const el = e.target;
      let bounding = el.getBoundingClientRect();
      this.$nextTick(() => {
        const swalForm = document.querySelector('body.swal2-toast-shown .swal2-container.swal2-center');
        swalForm.style.left = bounding.x + 'px'
        swalForm.style.top = bounding.y + 'px'
        swalForm.style.right = 'unset'
      })
      if (!this.hasPermission('approved_product')) {
        return;
      }
      const approveOrDeny = (approve) => {
        return this.$axios.post(`admin/products/${product.id}/${approve ? 'approve' : 'disapprove'}`)
            .then(response => {
              return response
            })
            .catch(error => {
              const message = error.data.message ? error.data.message : `درخواست با مشکل مواجه شد: ${error.data.message}`
              Swal.showValidationMessage(message)
            })
      }

      const value = await Swal.fire({
        title: 'تایید یا رد محصول شماره ' + product.id,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `تایید`,
        denyButtonText: `رد`,
        cancelButtonText: `لغو عملیات`,
        toast: true,
        preConfirm: (result) => {
          return approveOrDeny(true)
        },
        preDeny: (result) => {
          return approveOrDeny(false)
        }
      }).then((result) => {
        if (result.isConfirmed) {
          product.approved_at = this.moment()
          Swal.fire({
            title: `محصول با موفقیت تایید شد`,
            confirmButtonText: `باشه`,
          })
        } else if (result.isDenied) {
          product.approved_at = null
          Swal.fire({
            title: `محصول با موفقیت رد شد`,
            confirmButtonText: `باشه`,
          })
        }
      })
    }
  }
}
