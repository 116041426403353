import {BFormGroup, BFormInput, BFormTextarea, BTable, BModal, VBModal} from "bootstrap-vue";
import {getShippingPrice, setShipping} from '@@/core/libraries/functions'
import AddressModal from '@@/core/components/address/AddressModal/AddressModal'
import WalletModal from '@@/core/components/customer/CustomerWallet/CustomerWallet'
import CustomerForm from "@@/core/components/customer/CustomerForm/CustomerForm";
// import {customerFactory} from '@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEditImpl'

export default {
  components: {
    AddressModal, CustomerForm,
    WalletModal,BFormGroup, BFormInput, BFormTextarea, BTable, BModal
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    value: Object,
    mode: {
      default: "create",
      type: String,
    },
    url: String,
    customers: Array,
    shippings: Array,
    provinces: Array,
  },
  data() {
    return {
      productSelect: null,
      productsSearchMessage: '',
      disabled: false,
      btnDisabled: false,
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "amount",
          label: "قیمت واحد (تومان)",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "discount_amount",
          label: "تخفیف واحد (تومان)",
        },
        {
          key: "flash",
          label: "کمپین",
        },
        {
          key: "total_price",
          label: "قیمت نهایی (تومان)",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      addresses: [],
      customerWallet: null,
      addressShippings: [],
      addressMode: "create",
      address: {
        province: null,
        city: null,
        first_name: '',
        last_name: '',
        postal_code: '',
        mobile: '',
        address: '',
      },
      order: this.value,
      wallet: null,
      // customer: customerFactory()
    };
  },
  computed: {
    total() {
      if (this.order.varieties != "") {
        return this.order.varieties.reduce((sum, item) => {
          sum += item.amount * item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
    totalQuantity() {
      if (!this.order || this.order.varieties == "") {
        return 0;
      }
      return this.order.varieties.reduce((total, item)=> {
        return total + item.quantity;
      }, 0)
    },
    totalWithDiscount() {
      return this.total - this.order.discount_amount;
    },
    totalPrice() {
      if (this.order.varieties != "") {
        if (this.getShippingPrice != 0) {
          return this.totalWithDiscount + this.getShippingPrice;
        } else {
          return this.totalWithDiscount;
        }
      } else {
        return 0;
      }
    },
    getShippingPrice() {
      return getShippingPrice(this.order.address, this.order.shipping,  this.totalWithDiscount, this.totalQuantity)
    },
    disabledBtn() {
      if(this.customerWallet && +this.customerWallet.balance < this.totalPrice) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    order(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.order = newVal;
    },
    customers(newVal) {
      let customers = newVal.map((item) => {
        item.label = item.full_name + " - موبایل : " + item.mobile;
        return item;
      });
      return customers;
    },
    addresses(newVal) {
      let addresses = newVal.map((ad) => {
        ad.label =
            ad.city.province.name +
            " ، " +
            ad.city.name +
            " ، " +
            ad.address +
            " - گیرنده : " +
            ad.first_name +
            " " +
            ad.last_name +
            " - کد پستی : " +
            ad.postal_code;
        return ad;
      });
      return addresses;
    },
  },
  methods: {
    selectTheProduct(p) {
      this.productSelect = p;
    },
    setSearchMessage(m) {
      this.productsSearchMessage = m;
    },
    newWallet() {
      this.wallet.amount = 0
      this.$root.$emit('bv::show::modal', 'customer-wallet')
    },
    setNewWallet(newWallet) {
      this.customerWallet.balance = +this.customerWallet.balance + newWallet.amount
    },
    addNewAddress(newAddress) {
      this.addresses.push(newAddress);
    },
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
        customer_id: null,
      };
      this.address.customer_id = this.order.customer.id;
      this.addressMode = "create";
      window.$("#AddAddress1").modal("show");
    },
    setDisabled(val) {
      this.disabled = val;
    },
    deleteItem(index) {
      window
          .swal({
            title: "آیتم حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"],
          })
          .then((willDelete) => {
            if (willDelete) {
              this.$root.$delete(this.order.varieties, index);
            }
          })
          .catch(() => {});
    },
    async getCustomer(item) {
      const response = await this.$axios.get("admin/customers/" + item.id);
      let customer = response.data.data[0];
      let wallet = {
        customer_id : item.id,
        amount: 0
      }
      this.wallet = wallet
      this.customerWallet = customer.wallet;
      this.addresses = customer.addresses;
    },
    setShipping(item) {
      this.addressShippings = setShipping(item, this.shippings);
    },
    selectedVariety(item) {
      this.$nextTick(() => {
        const findVariety = this.order.varieties.find((variety) => {
          return variety.variety_id == item.id;
        });
        if (findVariety) {
          this.$root.notify(
              "این تنوع در لیست محصولات سفارش موجود است.",
              "warning"
          );
        } else if(item.quantity == 0) {
          this.$root.notify(
              "این تنوع موجودی ندارد.",
              "warning"
          );
        } else {
          let variety = {
            variety_id: item.id,
            variety: item,
            quantity: 1,
            amount: item.final_price.amount,
            discount_amount: item.final_price.discount_price,
            major_image: this.productSelect.major_image
          };
          this.order.varieties.push(variety);
        }
      })
    },
    decrement(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    increment(item) {
      if (item.quantity >= item.variety.quantity) {
        this.$root.notify(
            " از این کالا تنها " + item.variety.quantity + "  عدد موجود است ",
            "warning"
        );
      } else {
        item.quantity++;
      }
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData(e.target);
        formData.append("customer_id", this.order.customer ? this.order.customer.id : '');
        formData.append("address_id", this.order.address ? this.order.address.id : '');
        formData.append("shipping_id", this.order.shipping ? this.order.shipping.id : '');
        formData.append("discount_amount", this.order.discount_amount != 0 ? this.order.discount_amount : '');
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/order");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    onCustomerCreate(customer) {
      this.customers.unshift(customer)
      this.$root.$emit("bv::hide::modal", "newcustomer");
    }
  },
};
