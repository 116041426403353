<template>
  <form @submit.prevent='submit'>
    <div class="row">
      <div class="col-md-12">
        <b-form-group >
          <label for="name"> عنوان <i class="text-danger">*</i></label>
          <b-form-input v-model="scenario.title" name="title" type="text" placeholder="عنوان" />
        </b-form-group>
      </div>
      <div class="col-md-12">
        <b-form-group label="توضیح">
          <b-form-textarea v-model="scenario.description" name="description"></b-form-textarea>
        </b-form-group>
      </div>
     
    </div>
    <div class=" text-center mt-5">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        {{ mode == 'edit' ? ' ویرایش سناریو' : ' ثبت سناریو' }}</button>
    </div>
  </form>
</template>

<script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import scenarioFormImpl from '@@/core/components/scenario/scenarioForm/scenarioFormImpl'
  export default {
     components: {
      Treeselect
    },
    extends: scenarioFormImpl
  }
</script>

<style scoped>

</style>