<template>
  <main class="mt-5">
    <div class="card mt-3" style="min-height: max-content;">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش ایونت" : "ایونت جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <div class="d-flex ml-2" style="gap: 8px;">
          <button
          class="btn btn-primary"
          :class="{'btn-success':selectDefaultHall=='A'}"
          @click="selectDefaultValues('A')"
        >
          سالن A
        </button>
        <button
          class="btn btn-primary"
          :class="{'btn-success':selectDefaultHall=='B'}"
          @click="selectDefaultValues('B')"
        >
          سالن B
        </button>
        <button
          class="btn btn-primary"
          :class="{'btn-success':selectDefaultHall=='C'}"
          @click="selectDefaultValues('C')"
        >
          سالن C
        </button>
        </div>
      <Back />

      </div>
    </div>
    <div>
      <eventsForm :selectDefaultHall="selectDefaultHall" ref="eventsForm" />
    </div>
  </main>
</template>
<script>
import eventScoreImpl from "@@/core/pages/events/eventsCreateEdit/eventsCreateEditImpl";
export default {
  extends: eventScoreImpl,
};
</script>
