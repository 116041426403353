<template>
  <div>
    <!--Page header-->
    <div class="page-header d-xl-flex d-block mt-0 mb-3">
      <div class="page-leftheader">
        <h4 class="page-title">
          <span class="font-weight-normal text-muted ml-2">داشبورد</span>
        </h4>
      </div>

      <div v-if="10 / 2 === 2" class="page-leftheader mr-md-auto">
        <div
          class="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right"
        >
          <div class="d-flex">
            <div class="header-datepicker ml-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="feather feather-calendar"></i>
                  </div>
                </div>
                <input
                  class="form-control fc-datepicker"
                  placeholder="19 Feb 2020"
                  type="text"
                />
              </div>
            </div>
            <div class="header-datepicker ml-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="feather feather-clock"></i>
                  </div>
                </div>
                <!-- input-group-prepend -->
                <input
                  id="tpBasic"
                  type="text"
                  placeholder="09:30am"
                  class="form-control input-small"
                />
              </div>
            </div>
            <!-- wd-150 -->
          </div>
          <div class="d-lg-flex d-block">
            <div class="btn-list">
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#clockinmodal"
              >
                Clock In
              </button>
              <button
                class="btn btn-light"
                data-toggle="tooltip"
                data-placement="top"
                title="E-mail"
              >
                <i class="feather feather-mail"></i>
              </button>
              <button
                class="btn btn-light"
                data-placement="top"
                data-toggle="tooltip"
                title="Contact"
              >
                <i class="feather feather-phone-call"></i>
              </button>
              <button
                class="btn btn-primary"
                data-placement="top"
                data-toggle="tooltip"
                title="Info"
              >
                <i class="feather feather-info"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Page header-->

    <!--Row-->
    <div v-if="data" class="row">
      <div class="col-md-12 col-lg-12">
        <div class="row">
          <div class="numbers col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >تعداد رویداد های امروز
                      </span>
                      <h3 class="mb-0 mt-1 mb-2">
                        {{ data.today_events_count | price }}
                      </h3>
                      <!--                      <span class="text-muted">-->
                      <!--                                  <span class="text-success fs-12 mt-2 ml-1"><i-->
                      <!--                                      class="feather feather-arrow-up-right ml-1 bg-success-transparent p-1 brround"></i>124</span>-->
                      <!--                                  for last month-->
                      <!--                                </span>-->
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-success-transparent my-auto float-left"
                    >
                      <i class="feather feather-users"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="numbers col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >صندلی های رزرو شده امروز</span
                      >
                      <h3 class="mb-0 mt-1 mb-2">
                        {{ data.today_chairs_reserved_count | price }}
                      </h3>
                      <!--                      <span class="text-muted">-->
                      <!--                                  <span class="text-danger fs-12 mt-2 ml-1"><i-->
                      <!--                                      class="feather feather-arrow-down-left ml-1 bg-danger-transparent p-1 brround"></i>13</span>-->
                      <!--                                  for last month-->
                      <!--                                </span>-->
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-primary-transparent my-auto float-left"
                    >
                      <i class="feather feather-box"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="numbers col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >میزان فروش امروز</span
                      >
                      <h3 class="mb-0 mt-1 mb-2">
                        {{ data.today_sum_sell | price }}
                        <span style="font-size: 12px">تومان</span>
                      </h3>
                    </div>
                    <!--                    <span class="text-muted">-->
                    <!--                                <span class="text-danger fs-12 mt-2 ml-1"><i-->
                    <!--                                    class="feather feather-arrow-up-right ml-1 bg-danger-transparent p-1 brround"></i>21.1% </span>-->
                    <!--                                for last month-->
                    <!--                              </span>-->
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-secondary-transparent brround my-auto float-left"
                    >
                      <i class="feather feather-dollar-sign"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="numbers col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >میزان فروش کل
                      </span>
                      <h3 class="mb-0 mt-1 mb-2">
                        {{ data.total_sum_sell | price }}
                        <span style="font-size: 12px">تومان</span>
                      </h3>
                    </div>
                    <!--                    <span class="text-muted">-->
                    <!--                                <span class="text-danger fs-12 mt-2 ml-1"><i-->
                    <!--                                    class="feather feather-arrow-up-right ml-1 bg-danger-transparent p-1 brround"></i>21.1% </span>-->
                    <!--                                for last month-->
                    <!--                              </span>-->
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-secondary-transparent brround my-auto float-left"
                    >
                      <i class="feather feather-dollar-sign"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header border-0">
          <h5 class="card-title font-bold">لیست ایونت های امروز</h5>
          <div style="flex: 1"></div>
        </div>
        <div class="card-body">
          <div style="overflow: auto">
            <b-table
              hover
              bordered
              :items="data.today_events"
              :fields="today_eventsFields"
            >
              <template v-slot:cell(scenario)="data">
                {{ data.item.scenario.title }}
              </template>

              <template v-slot:cell(register)="data">
                <router-link
                  target="_blank"
                  :to="`/admin/events/register-users/${data.item.id}?add=true`"
                  v-if="data.item.remaining_capacity != 0"
                  class="action-btns1 pt-1 px-2"
                >
                  <i class="feather feather-plus-square text-primary"></i>
                </router-link>
                <a v-else class="action-btns1 pt-1 px-2">
                  <i class="feather feather-check text-success font-bold"></i>
                </a>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <Loading v-else-if="!notLoading" />
  </div>
</template>

<script>
import DashboardImpl from "@@/core/pages/Dashboard/DashboardImpl";
import ActivityLog from "@@/core/components/dashboard/ActivityLog/ActivityLog";
export default {
  components: { ActivityLog },
  extends: DashboardImpl,
};
</script>

<style scoped src="@@/core/pages/Dashboard/DashboardStyle.css"></style>
