import {Treeselect} from '@riophae/vue-treeselect'
import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "ProductPublishing",
  components: {
    InputGroup,
    Treeselect,
    BFormCheckbox
  },
  props: {
    value: Object,
    submit: Function,
    disabled: Boolean
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'available',
          label: 'موجود',
        },
        {
          id: 'soon',
          label: 'بزودی',
        },
        {
          id: 'out_of_stock',
          label: 'ناموجود',
        },
        {
          id: 'draft',
          label: 'پیش نویس',
        },
      ],
      realValue: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal
    },
    realValue(newVal) {
      this.$emit('input', newVal)
    },
  },

}
