<template>
  <b-form @submit.prevent="submit">
    <b-tabs>
      <b-tab title="اطلاعات پایه">
        <div class="row">
          <b-form-group class="col-md-6" label="نام " label-for="first-name">
            <b-form-input
              :disabled="disabled"
              id="first-name"
              name="first_name"
              v-model="user.first_name"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="col-md-6"
            label="نام خانوادگی "
            label-for="last-name"
          >
            <b-form-input
              :disabled="disabled"
              id="last-name"
              name="last_name"
              v-model="user.last_name"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6">
            <label for="mobile">
              شماره همراه <i class="text-danger">*</i></label
            >
            <b-form-input
              :disabled="disabled"
              id="mobile"
              name="mobile"
              v-model="user.mobile"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6">
            <label for="password"> کلمه عبور</label>
            <b-form-input
              type="password"
              :disabled="disabled"
              id="password"
              name="password"
              v-model="user.password"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" label="ایمیل" label-for="email">
            <b-form-input
              :disabled="disabled"
              id="email"
              name="email"
              v-model="user.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" label-for="code">
            <label for="code"> کد ملی <i class="text-danger">*</i></label>
            <b-form-input
              :disabled="disabled"
              id="code"
              name="national_code"
              v-model="user.national_code"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" label="جنسیت " label-for="gender">
            <multiselect
              v-model="user.gender"
              placeholder="انتخاب کنید"
              :allow-empty="false"
              open-direction="bottom"
              :options="items"
              label="name"
              track-by="value"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            class="col-md-6"
            label="بیوگرافی"
            label-for="card-number"
          >
            <b-form-input
              :disabled="true"
              id="card-number"
              name="biography"
              v-model="user.biography"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="col-md-6"
            label="تاریخ تولد "
            label-for="birth-date"
          >
            <date-picker
              v-model="user.birth_date"
              name="birth_date"
              format="jYYYY-jMM-jDD"
            />
          </b-form-group>
        </div>
      </b-tab>
      <b-tab title="کارت بازیکن">
        <div class="row">
          
        <b-form-group class="col-md-6" label="سناریو " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="best_scenario"
            v-model="user.best_scenario"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="تشخیص صحیح " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="correct_diagnosis"
            v-model="user.correct_diagnosis"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="تارگت صحیح " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="correct_target"
            v-model="user.correct_target"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="فریب " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="deception"
            v-model="user.deception"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="تاثیرگذاری " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="influence"
            v-model="user.influence"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="بازی شهروندی " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="play_on_city"
            v-model="user.play_on_city"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="بازی مافیایی " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="play_on_mafia"
            v-model="user.play_on_mafia"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="استدلال " label-for="">
          <b-form-input
            :disabled="disabled"
            id=""
            name="reasoning"
            v-model="user.reasoning"
          ></b-form-input>
        </b-form-group>
        </div>
      </b-tab>
    </b-tabs>
    <div class="col-md-12 text-center mt-5">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        {{ mode == "edit" ? " ویرایش کاربر" : " ثبت کاربر" }}
      </button>
    </div>
  </b-form>
</template>
<script>
import userFormImpl from "@@/core/components/user/userForm/userFormImpl";

export default {
  extends: userFormImpl,
};
</script>
<style scoped src="@@/core/components/user/userForm/userFormStyle.css"></style>
