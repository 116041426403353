import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { findIndex } from "lodash";

export default {
  name: "galleryForm",
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
  },

  data() {
    return {
      isRequesting:false,
      events:[],
      selectedEvent:"",
      usersList: [],
      rows: [{ main_image: "", users: [] }],
      lastQ: "",
      gallery: {
        title: "",
        main_image: "",
      },
      mode: "",
      url: "/admin/gallery",
      disabled: false,
    };
  },
  mounted() {
    this.mode = this.$route.params.id ? "edit" : "create";
    this.url =
      this.mode === "edit" ? this.url + "/" + this.$route.params.id : this.url;
    if (this.mode === "edit") {
      this.$axios.get(this.url).then((res) => {
        this.gallery = res.data.data.gallery;
      });
    }
  },

  methods: {
    async getEvent(q) {
      if (this.lastQ == q || q == '' || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel()
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        this.isRequesting = true
        const response = await this.$axios.get(`admin/events?search=${q}`, {
          cancelToken: this.axiosProductsCancelSource.token,
        })
        this.events = response.data.data.eventGames.data
        
        this.isRequesting = false
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e)
        }
      }
    },
    trashRow(row) {
      let findedRow = this.rows.findIndex((res) => {
        return JSON.stringify(row) === JSON.stringify(res);
      });
      if (findedRow != -1) {
        this.rows.splice(findedRow, 1);
      }
    },
    addRow() {
      this.rows.push({ main_image: "", users: [] });
    },
    async getUser(q) {
      if (this.lastQ == q || q == "" || q.length == 1) {
        return;
      }
      this.lastQ = q;
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel();
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        const response = await this.$axios.get(
          `admin/search_user?search=${q}`,
          {
            cancelToken: this.axiosProductsCancelSource.token,
          }
        );
        this.usersList = response.data.data.users.map((c) => {
          c.label = c.id + " - " + c.mobile;
          if (c.first_name || c.last_name) {
            c.label += " - ";
          }
          if (c.first_name) {
            c.label += c.first_name;
          }
          if (c.last_name) {
            c.label += c.last_name;
          }
          return c;
        });
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e);
        }
      }
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData();
        formData.append("title", this.gallery.title);
        formData.append("status", 1);
        formData.append("eventgame_id",this.selectedEvent?this.selectedEvent.id:null)
        formData.append("main_image", this.gallery.main_image);
        this.rows.forEach((row, index) => {
          formData.append(`gallery_items[${index}][type]`, "image");
          formData.append(`gallery_items[${index}][images][0]`, row.main_image);
          let ids = [];
          row.users.forEach((user) => ids.push(user.id));
          formData.append(`gallery_items[${index}][user_ids]`, ids.toString());
        });
        if (this.mode == "edit") {
          formData.append("_method", "put");
        }
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/gallery");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
