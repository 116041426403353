<template>
  <b-modal :ok-disabled="disabled" :cancel-disabled="disabled" @ok="submit"
           class="in-form-modal" ok-title="ثبت" cancel-title="لغو" :id="'modal-' + modalName" :title="getTitle()">
    <b-form ref="form" @submit.prevent="submit">
      <template v-for="input in inputs">
        <Input :disabled="disabled" :label="input.label"
               :required="input.required" :always-disabled="input.alwaysDisabled"
               :key="input.name" :name="input.name" :type="input.type" v-model="input.value" :items="input.items"
               :select_label="input.select_label" :id="input.id" :custom_selects="input.customSelects"
               :options="input.options"
        />
      </template>
      <input type="submit" style="visibility: hidden;position: absolute" />
    </b-form>
  </b-modal>
</template>

<script>
import formMixin from "@@/core/libraries/form/mixins/formMixin";
import getTranslations from "@@/core/libraries/form/translations";
import {BModal, BForm} from "bootstrap-vue";

import pluralize from 'pluralize'
export default {
  name: 'FormModal',
  mixins: [formMixin],
  props: {
    modalName: String,
    title: String
  },
  components: {
    BModal, BForm
  },
  methods: {
    getTitle() {
      const pre = this.mode === 'create' ? 'ثبت ' : 'ویرایش ';

      if (this.title) {
        return pre + this.title;
      }
      return pre + getTranslations(pluralize.singular(this.modalName))
    }
  }
}
</script>
