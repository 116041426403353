<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link to="/admin/events/create?for=tournament" class="btn btn-success"
        >ایونت جدید</router-link
      >
    </span>

    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">ایونت ها</h5>
        <div style="flex: 1"></div>
      </div>
      <div class="card-body">
        <div v-if="list" style="overflow: auto">
          <b-table hover bordered :items="list" :fields="fields">
            <template v-slot:cell(god)="data">
              {{ data.item.god.first_name + " " + data.item.god.last_name }}
            </template>
            <template v-slot:cell(score)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  :to="'/admin/events/score/' + data.item.id"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="fa fa-star text-success"></i>
                </router-link>
              </span>
            </template>
            <template v-slot:cell(event_date)="data">
              <span >
               {{ new Date(data.item.event_date).toLocaleDateString("fa") }}
              </span>
            </template>
            <template v-slot:cell(users)="data">
                <a
                @click="showUsers(data.item)"
                  class="action-btns1 pt-1 px-2"
                >
                  <i class="feather feather-users text-primary"></i>
                </a>
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  :to="'/admin/events/edit/' + data.item.id"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
            </template>
            <template v-slot:cell(delete)="data">

              <span :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
          </b-table>
        </div>
        <Loading v-else />
      </div>
    </div>
  </main>
</template>

<script>
import eventsImpl from "@@/core/pages/tournaments/events/eventsImpl";
export default {
  extends: eventsImpl,
};
</script>

