import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";

import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/orders",
      allSelected: false,
      widgets: [],
      btnStatus: false,
      selectedOrders: Array(100).fill(false),
      fields: [
        // {
        //   key: "select",
        //   label: "",
        // },
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "user",
          label: "مشتری",
        },
        {
          key: "eventTitle",
          label: "رویداد",
        },
        {
          key: "chairs",
          label: "شماره صندلی",
        },
        {
          key: "total_price",
          label: "مبلغ کل",
        },

        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
      ],
      order: {},
    };
  },
  mounted() {
    this.loadItems();
  },

  methods: {
    findChairs(list) {
      let chairs = [];
      list.forEach((item) => {
        if (item.content) {
          chairs.push(item.content.chair_number);
        }
      });
      return chairs.toString();
    },
  },
};
