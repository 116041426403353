<template>
  <b-modal id="customer-modal" hide-footer title="اطلاعات مشتری">
    <div class="d-flex my-1">
      <h6 class="font-bold">شناسه :</h6>
      <h6 class="pr-1">{{ customer.id }}</h6>
    </div>
    <div class="d-flex my-1">
      <h6 class="font-bold">نام و نام خانوادگی :</h6>
      <h6 class="pr-1">{{ customer.full_name }}</h6>
    </div>
    <div v-if="customer.national_code != null" class="d-flex my-1">
      <h6 class="font-bold">کد ملی :</h6>
      <h6 class="pr-1">{{ customer.national_code }}</h6>
    </div>
    <div v-if="customer.foreign_national == 1" class="d-flex my-1">
      <h6 class="font-bold">تبعه خارجی :</h6>
      <h6 class="pr-1">{{ customer.foreign_national }}</h6>
    </div>
    <div class="d-flex my-1">
      <h6 class="font-bold">ایمیل :</h6>
      <h6 class="pr-1">{{ customer.email }}</h6>
    </div>
    <div class="d-flex my-1">
      <h6 class="font-bold">تاریخ تولد :</h6>
      <h6 class="pr-1">{{ customer.birth_date }}</h6>
    </div>
    <div class="d-flex my-1">
      <h6 class="font-bold">جنسیت :</h6>
      <h6 class="pr-1">
        <template v-if="customer.gender == 'female'">
          زن
        </template>
        <template v-else-if="customer.gender == 'male'">
          مرد
        </template>
      </h6>
    </div>
    <div class="d-flex my-1">
      <h6 class="font-bold">شماره کارت :</h6>
      <h6 class="pr-1">{{ customer.card_number }}</h6>
    </div>
    <div class="d-flex my-2">
      <h6 class="font-bold">موجودی کیف پول :</h6>
      <h6 class="pr-1" v-if="customer.wallet && customer.wallet.balance != 0">
        {{ customer.wallet.balance | price }} تومان
      </h6>
      <h6 class="pr-1" v-else>موجودی ندارد</h6>
    </div>
    <button type="button" class="btn btn-outline-primary py-0" @click="newWallet()">
      افزایش موجودی
      <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 226 226"
          style=" fill:#000000;"
      >
        <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
        >
          <path d="M0,226v-226h226v226z" fill="none"></path>
          <g fill="#3366ff">
            <path
                d="M156.505,13.70125c-3.53125,0.05297 -7.43328,0.82984 -12.28875,2.11875c-13.93078,3.6725 -94.0725,25.84875 -94.0725,25.84875c-0.14125,0.03531 -0.2825,0.08828 -0.42375,0.14125c-12.69484,4.78484 -20.69313,9.28719 -25.56625,15.82c-4.83781,6.4975 -5.91484,14.30156 -5.9325,23.58875c0,0.05297 0,0.08828 0,0.14125v2.825c-0.05297,0.565 -0.14125,1.13 -0.14125,1.695v96.615c0,13.96609 11.45891,25.425 25.425,25.425h138.99c13.96609,0 25.425,-11.45891 25.425,-25.425v-97.32125c0,-14.4075 -12.88906,-26.41375 -27.12,-26.41375h-135.6c-5.06734,0 -9.62266,1.44781 -13.41875,3.81375c3.28406,-4.04328 9.48141,-7.85703 20.76375,-12.1475c0.12359,-0.05297 0.15891,-0.08828 0.2825,-0.14125c0.51203,-0.14125 80.17703,-22.15859 93.64875,-25.7075c4.48469,-1.18297 7.83937,-1.80094 10.17,-1.83625c2.33062,-0.03531 3.42531,0.31781 3.955,0.70625c0.52969,0.38844 0.97109,1.14766 1.4125,2.96625c0.44141,1.81859 0.70625,4.59062 0.70625,8.1925v15.11375h9.04v-15.11375c0,-3.91969 -0.24719,-7.23906 -0.98875,-10.31125c-0.74156,-3.07219 -2.18937,-6.09141 -4.8025,-8.05125c-2.61312,-1.95984 -5.9325,-2.59547 -9.46375,-2.5425zM45.2,67.8h135.6c8.81047,0 18.08,8.7575 18.08,17.37375v97.32125c0,9.07531 -7.30969,16.385 -16.385,16.385h-138.99c-9.09297,0 -16.385,-7.29203 -16.385,-16.385v-89.97625l0.14125,-7.48625c0.26484,-3.07219 1.83625,-7.45094 4.8025,-10.87625c3.23109,-3.74313 7.64516,-6.35625 13.13625,-6.35625zM174.02,122.04c-6.25031,0 -11.3,5.04969 -11.3,11.3c0,6.25031 5.04969,11.3 11.3,11.3c6.23266,0 11.3,-5.04969 11.3,-11.3c0,-6.25031 -5.06734,-11.3 -11.3,-11.3z"
            ></path>
          </g>
        </g>
      </svg>
    </button>
    <router-link :to="`/admin/customers/show/${customer.id}`" class="btn btn-outline-success mx-2 py-0" @click="newWallet()">مشاهده کاربر</router-link>
  </b-modal>
</template>

<script>
import CustomerModalImpl from "@@/core/components/order/CustomerModal/CustomerModalImpl";

export default {
  name: "CustomerModal",
  extends: CustomerModalImpl
}
</script>

<style scoped>

</style>
