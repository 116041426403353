<template>
  <main class="mt-5">
    <span id="add-btn">
      <button @click="createItem" v-b-modal.games class="btn btn-success">
      بازی جدید
      </button>
    </span>
    <template>
      <b-modal
        :ok-disabled="disable"
        :cancel-disabled="disable"
        @ok="submitGmes"
        class="in-form-modal"
        ok-title="ثبت"
        cancel-title="لغو"
        :id="'modal-games'"
        :title="mode == 'create' ? 'ثبت بازی' : 'ویرایش بازی'"
      >
        <b-form ref="form" @submit.prevent="submitGmes">
          <b-form-group label="عنوان بازی را وارد کنید :">
            <b-form-input v-model="title" type="text"></b-form-input>
          </b-form-group>
          <b-form-group label="تصویر بازی را وارد کنید :">
            <b-form-file v-model="main_image"></b-form-file>
          </b-form-group>
        </b-form>
      </b-modal>
    </template>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">بازی ها</h5>
        <div style="flex: 1"></div>

      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-6">
                <b-form-input
                  :disabled="disabled"
                  placeholder="نام دسته بندی"
                  v-model="filter.name"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-6">
                <multiselect
                  placeholder="وضعیت"
                  v-model="filter.status"
                  open-direction="bottom"
                  :options="status_options"
                  label="name"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
          >
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 1"
                class="badge badge-success status"
                >فعال</span
              >
              <span
                v-else-if="data.item.status == 0"
                class="badge badge-danger status"
                >غیرفعال</span
              >
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a
                  
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.item)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </a>
              </span>
            
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="
                    !hasPermission('delete_post-category') ? true : false
                  "
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_post-category')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import gamesImpl from "@@/core/pages/festival/games/games/gamesImpl";
export default {
  extends: gamesImpl,
};
</script>
