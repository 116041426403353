import Loading from "@@/core/components/shared/Loading/Loading";
import menuModal from "@@/core/components/menu/MenuForm/MenuForm";
import "@@/assets/admin/vue-nestable.css";
import { BButton, BTooltip, VBModal } from "bootstrap-vue";
import { VueNestable, VueNestableHandle } from "vue-nestable";

export default {
  components: {
    Loading,
    menuModal,
    BTooltip,
    BButton,
    VueNestable,
    VueNestableHandle,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      url: "",
      group: "",
      menu_items: null,
      edit: {},
      mode: "create",
      linkables: [],
      menu_item: {
        title: "",
        status: 1,
        link: null,
        linkable_type: null,
        linkable_id: null,
        new_tab: 0,
        group_id: "",
      },
      modelLable: "",
      disabled: false,
      change: false,
      options: [],
    };
  },
  watch: {
    menu_items() {
      this.options = this.menu_items;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    this.url = `admin/menu_items/${this.$route.params.group}`;
    this.group = +this.$route.params.group;
    this.loadMenu();
  },
  methods: {
    refreshMenu() {
      this.loadMenu();
    },
    onChange() {
      this.change = true;
    },
    async loadMenu() {
      const response = await this.$axios.get(this.url);
      this.menu_items = response.data.data.menu_items;
      this.options = this.menu_items;
      this.linkables = response.data.data.widgets.linkables;
      this.linkables.push({
        linkable_type: "link_url",
        unique_type: "link_url",
        label: "لینک دلخواه",
        models: "link_url",
      });
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(`admin/menu_items/${id}`)
              .then((response) => {
                this.refreshMenu();
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    editItem(item) {
      this.menu_item = {
        ...item,
      };
      // this.options = this.menu_items.filter(m => m.id =! item.id)
      // console.log(this.menu_item);
      if (this.menu_item.link == null) {
        this.menu_item.linkable_type = this.linkables.filter(
          (item) => item.unique_type == this.menu_item.unique_type
        )[0];

        this.menu_item.linkable_id != null &&
        this.menu_item.linkable_type.models
          ? (this.menu_item.linkable_id =
              this.menu_item.linkable_type.models.filter(
                (item) => item.id == this.menu_item.linkable_id
              )[0])
          : "";

        if (
          this.menu_item.linkable_type.models != null &&
          this.menu_item.linkable_type.models[0].title
        ) {
          this.modelLable = "title";
        } else if (
          this.menu_item.linkable_type.models != null &&
          this.menu_item.linkable_type.models[0].name
        ) {
          this.modelLable = "name";
        }
      } else {
        this.menu_item.linkable_type = {
          linkable_type: "link_url",
          unique_type: "link_url",
          label: "لینک دلخواه",
          models: "link_url",
        };
      }
      this.mode = "edit";

      this.menu_item.status
        ? (this.menu_item.status = 1)
        : (this.menu_item.status = 0);
      this.menu_item.new_tab
        ? (this.menu_item.new_tab = 1)
        : (this.menu_item.new_tab = 0);
      this.$root.$emit("bv::show::modal", "menu-modal");
    },
    newMenu() {
      (this.menu_item = {
        title: "",
        status: 1,
        link: null,
        linkable_type: null,
        linkable_id: null,
        new_tab: 0,
        group_id: this.group,
      }),
        (this.mode = "create");
    },
    async sortMenu() {
      let sanitizeMenu = this.menu_items.map((i) => this.sanitizeMenu(i));
      try {
        this.disabled = true;
        const response = await this.$axios.post("admin/menu_items/sort", {
          menu_items: sanitizeMenu,
          group_id: this.group,
        });
        this.$root.notify(response.data.message, "success");
        this.change = false;
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    sanitizeMenu(c) {
      return {
        id: c.id,
        children: c.children.map((cc) => this.sanitizeMenu(cc)),
      };
    },
  },
};
