<template>
  <main class="mt-5">
    <div class="card mt-3" style="min-height: max-content;">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش تورنومنت" : "تورنومنت جدید" }}
        </h5>
        <div style="flex: 1"></div>
       
      <Back />

      </div>
    </div>
    <div>
      <tournamentForm ref="eventsForm" />
    </div>
  </main>
</template>
<script>
import createEditImpl from "@@/core/pages/tournaments/createEdit/createEditImpl";
export default {
  extends: createEditImpl,
};
</script>
