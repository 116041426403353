import Loading from '@@/core/components/shared/Loading/Loading'
import ProductComment from '@@/core/components/product/ProductCommentShow/ProductCommentShow'
import mixins from "@@/core/mixins/mixins";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BFormRating,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
  BCollapse
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    Loading,
    ProductComment,
    BTooltip, BButton, BCard, BTable, BFormRating, BFormGroup, BFormInput, BCollapse
  },
  directives: {
    'b-toggle': VBToggle, 'b-tooltip': VBTooltip,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/product-comment",
      // widgets: ["list_products"],
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "body",
          label: "نظر",
        },
        {
          key: "rate",
          label: "امتیاز",
        },
        {
          key: "customer",
          label: "کاربر",
        },
        {
          key: "product",
          label: "محصول",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "show",
          label: "مشاهده",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      order: {},
      statusOptions: [
        {
          value: "approved",
          title: "تایید شده",
        },
        {
          value: "pending",
          title: "در انتظار بررسی",
        },
        {
          value: "reject",
          title: "رد شده",
        },
        {
          value: "",
          title: "همه",
        },
      ],
      filter: {
        product_id: "",
        status: "",
        start_date: "",
        end_date: "",
      },
      comment: {},
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    "filter.status": function(newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.status = this.statusOptions.find((item) => {
          return item.value == newVal;
        });
      }
    },
    "filter.product_id": function(newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.product_id = this.widgetData.products.find((item) => {
          return item.id == newVal;
        });
      }
    },
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.product_id ? this.filter.product_id.id : "",
        searchBy1: "product_id",
        search2: this.filter.status ? this.filter.status.value : "",
        searchBy2: "status",
        start_date: this.filter.start_date
            ? window.moment(this.filter.start_date, "YYYY.MM.DD").unix()
            : "",
        end_date: this.filter.end_date
            ? window.moment(this.filter.end_date, "YYYY.MM.DD").unix()
            : "",
      };
    },
    showComment(index) {
      this.comment = this.items.data[index];
      this.comment.index = index;
      this.$root.$emit("bv::show::modal", "comment-modal");
    },
  },
};
