<template>
  <main class="mt-5">
    <div>
      <eventScoreForm ref="eventsForm" />
    </div>
  </main>
</template>
<script>
import eventScoreImpl from "@@/core/pages/events/eventScore/eventScoreImpl";
export default {
  extends: eventScoreImpl,
};
</script>
