<template>
  <main class="mt-5">
    <span id="add-btn">
      <!-- <router-link
        to="/admin/order/create"
        :disabled="hasPermission('write_order') ? false : true"
        type="button"
        tabindex="-1"
        class="btn btn-success"
        @click="newOrder()"
      >
        سفارش جدید
      </router-link> -->
    </span>
    <!-- <b-tooltip
      v-if="!hasPermission('write_order')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip> -->
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold py-3">پرداختی ها</h5>
        <div style="flex: 1"></div>
        <!-- <b-button
          class="mr-2"
          v-if="btnStatus"
          variant="light"
          v-b-modal.change-status
        >
          تغییر وضعیت
        </b-button>
        <b-button
          class="mr-2"
          v-if="btnStatus"
          variant="warning"
          @click="printOrders()"
        >
          چاپ <i class="fe fe-printer"></i>
        </b-button>
        <b-button
          v-b-toggle.filter
          :variant="hasFilter() ? 'secondary' : 'primary'"
          class="mr-2"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px"></span>
        </b-button> -->
      </div>
      <!-- <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="شناسه"
                  v-number
                  v-model="filter.id"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="کد رهگیری"
                  v-number
                  v-model="filter.tracking_code"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="شهر"
                  v-model="filter.city"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="استان"
                  v-model="filter.province"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="نام"
                  v-model="filter.first_name"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <b-form-input
                  :disabled="disabled"
                  placeholder="نام خانوادگی"
                  v-model="filter.last_name"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                v-if="hasPermission('read_customer')"
                class="col-md-3"
              >
                <CustomerSearch
                  v-model="filter.customer"
                  :allow-empty="true"
                  :show-label="false"
                />
              </b-form-group>
              <b-form-group v-if="widgetData.products" class="col-md-3">
                <multiselect
                  placeholder="محصول"
                  v-model="filter.product_id"
                  open-direction="bottom"
                  :options="products"
                  label="title"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect
                  placeholder="وضعیت"
                  v-model="filter.status"
                  open-direction="bottom"
                  :options="statusFilterOptions"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions"> موردی یافت نشد </template>
                  <template slot="noResult"> موردی یافت نشد </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="از تاریخ"
                  v-model="filter.start_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="تا تاریخ"
                  v-model="filter.end_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-12">
                <ProductSearch
                  ref="productSearch"
                  :selectable="true"
                  class="row"
                  :disabled="disabled"
                  @selectProduct="selectProduct"
                  :show-label="false"
                  @selectVariety="selectVariety"
                />
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse> -->
      <div class="card-body pt-1">
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
            :style="disabled && items && items.data ? 'opacity: 0.7' : ''"
          >
            <template #cell(eventTitle)="data">
              {{ data.item.items[0]?.content?.eventgame?.title }}
            </template>
            <template #cell(chairs)="data">
              {{ findChairs(data.item.items) }}
            </template>
            <template #cell(status)="data">
              <span
                class="badge"
                :class="{
                  'badge-warning': data.item.invoice.status == 'pending',
                  'badge-danger': data.item.invoice.status == 'failed',
                  'badge-success': data.item.invoice.status == 'success',
                }"
              >
                {{
                  data.item.invoice.status == "success"
                    ? "موفق"
                    : data.item.invoice.status == "failed"
                    ? "خطا"
                    : "درحال بررسی"
                }}
              </span>
            </template>
            <template #cell(total_price)="data">
              {{ data.item.total_price | price }}
            </template>
            <template #cell(user)="row">
              {{
                row.item.user && row.item.user.first_name
                  ? row.item.user.first_name +
                    " - " +
                    row.item.user.first_name +
                    " " +
                    row.item.user.mobile
                  : row.item.user.mobile
              }}
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import OrdersImpl from "@@/core/pages/order/Orders/OrdersImpl";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
import CustomerSearch from "@@/core/components/shared/CustomerSearch/CustomerSearch";
export default {
  components: { CustomerSearch, ProductSearch },
  extends: OrdersImpl,
};
</script>
<style scoped>
.badge {
  font-size: 14px;
}
.orders-tooltip {
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  right: -4px;
  top: 50%;
  transform: translate(100%, -50%);
  border-radius: 5px;
  background: #024c5c;
  display: flex;
  flex-direction: column;
  color: white;
}

.orders-tooltip span {
  margin: 4px 0;
}

.orders-tooltip:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 7px solid #0f6674;
}
</style>
