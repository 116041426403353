<template>
  <b-modal id="comment-modal" title="مشاهده نظر" hide-footer size="lg">
    <div class="my-2">
            <span class="font-bold">
                کاربر :
            </span>
      <span v-if="comment.creator">
                {{ comment.creator.first_name && comment.creator.last_name ? comment.creator.first_name + ' ' + comment.creator.last_name : comment.creator.mobile }}
            </span>
    </div>
    <div class="my-2">
            <span class="font-bold">
                محصول :
            </span>
      <span>
                {{ comment.product ? comment.product.title : '' }}
            </span>
    </div>
    <div class="my-2 d-flex align-items-center" v-if="comment.product">
            <span class="font-bold">
                امتیاز :
            </span>
      <span class="d-block" style="width:200px">
                <b-form-rating
                    v-model="comment.rate"
                    variant="warning"
                    :readonly="true"
                    class="border-0 comment-rate pb-0 pt-1 "
                >
              </b-form-rating>
            </span>
    </div>
    <div class="my-2">
            <span class="font-bold">
                نظر :
            </span>
      <span>
                {{ comment.body }}
            </span>
    </div>
    <div class="mt-4 mb-1 text-center">
            <span id="add-btn">
                <button @click="changeStatus('approved')" class="btn btn-success mx-1"
                        :disabled="!hasPermission('modify_productComment') || disabled || comment.status =='approved' ? true : false">تایید نظر</button>
                <button @click="changeStatus('reject')" class="btn btn-danger mx-1"
                        :disabled="!hasPermission('modify_productComment') || disabled || comment.status =='reject' ? true : false ">رد نظر</button>
                <button @click="changeStatus('pending')" class="btn btn-warning mx-1"
                        :disabled="!hasPermission('modify_productComment') || disabled || comment.status =='pending' ? true : false">در انتظار بررسی</button>
              </span>
      <b-tooltip v-if="!hasPermission('modify_productComment')" target="add-btn" triggers="hover">
        شما به این بخش دسترسی ندارید
      </b-tooltip>

    </div>
  </b-modal>
</template>
<script>
import ProductCommentShowImpl from '@@/core/components/product/ProductCommentShow/ProductCommentShowImpl'

export default {
  extends: ProductCommentShowImpl
}
</script>
