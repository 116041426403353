import { BTable, BFormInput } from "bootstrap-vue";
import Loading from "@@/core/components/shared/Loading/Loading";
import Back from "@@/core/components/shared/Back/Back";

export default {
  name: "eventsForm",
  components: {
    Back,
    Loading,
    BTable,
    BFormInput,
  },

  mounted() {
    this.$axios
      .get(`admin/admin_events_referee/${this.$route.params.id}`)
      .then((res) => {
        this.mainData = res.data.data;
        this.mainData.unique_users.forEach((user) => {
          this.mainData.checklists.forEach((cl) => {
            this.listInputs[`user_${user.id}_checklist_${cl.id}`] = "";
          });
        });
        this.mainData.checklists.forEach((item) => {
          this.fields.push({
            key: item.id,
            label: item.title,
          });
        });
        if (this.mainData.filled_checklists.length) {
          this.mainData.filled_checklists.forEach((clItem) => {
            this.listInputs[
              `user_${clItem.user_id}_checklist_${clItem.checklist_id}`
            ] = clItem.achieved_score;
          });
        }
      })
      .catch((err) => {
        this.$root.notify(err);
      });
  },
  data() {
    return {
      disabled: false,
      mainData: null,
      fields: [],
      listInputs: {},
    };
  },
  methods: {
    submitScore() {
      this.disabled = true;
      let fd = new FormData();
      let clLength = this.mainData.checklists.length;
      this.mainData.unique_users.forEach((user, index) => {
        this.mainData.checklists.forEach((cl, index2) => {
          fd.append(`scores[${clLength * index + index2}][user_id]`, user.id);
          fd.append(
            `scores[${clLength * index + index2}][checklist_id]`,
            cl.id
          );
          fd.append(
            `scores[${clLength * index + index2}][achieved_score]`,
            this.listInputs[`user_${user.id}_checklist_${cl.id}`]
          );
        });
      });
      this.$axios
        .post(`admin/store_checklist/${this.$route.params.id}`, fd)
        .then((res) => {
          this.$root.notify(res.data.message, "success");
          this.$router.push("/admin/events");
          this.disabled = false;
        })
        .catch((err) => {
          this.$root.notify(err);
          this.disabled = false;
        });
    },
  },
};
