<template>
  <form @submit.prevent="submit">
    <div v-if="mainData" class="row">
      <div class="col-12">
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">
              امتیاز دهی به رویداد {{ mainData.event.title }}
            </h5>
            <div style="flex: 1"></div>
            <Back />
    
          </div>
          <div class="card-body">
              <table id="scoreTable" class="table table-bordered">
                <thead>
                  <tr>
                    <th>بازیکن/چک لیست</th>
                    <th v-for="(item, index) in fields" :key="index">
                      {{ item.label }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(user, index) in mainData.unique_users"
                    :key="index"
                  >
                    <td>
                      {{
                        user.first_name
                          ? user.first_name + " " + user.last_name
                          : user.mobile
                      }}
                    </td>
                    <th v-for="item in fields" :key="item.key">
                      <b-form-input type="number" v-model="listInputs[`user_${user.id}_checklist_${item.key}`]">
                      </b-form-input>
                    </th>
                  </tr>
                </tbody>
              </table>
              <button :disabled="disabled" @click="submitScore()" class="btn btn-primary">
                ثبت امتیاز
              </button>
          </div>
        </div>
      </div>
    </div>
    <Loading v-else />
  </form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import eventScoreFormImpl from "@@/core/components/events/eventScoreForm/eventScoreFormImpl";
export default {
  components: {
    Treeselect,
  },
  extends: eventScoreFormImpl,
};
</script>

<style scoped>

</style>
