import Back from '@@/core/components/shared/Back/Back'
import eventsForm from '@@/core/components/events/eventsForm/eventsForm'

    export default {
        components: {
            Back,
            eventsForm
        },
        data() {
            return {
                selectDefaultHall:""
            }
        },
        methods: {
            selectDefaultValues(e){
                this.selectDefaultHall=e;
            }
            
        },
       
    }
