<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 v-if="festival" class="card-title font-bold">
          {{ `لیست کاربران ثبت نام کرده در ${festival.title}` }}
        </h5>
        <div style="flex: 1"></div>
        <!-- <b-button
          ref="menuCollapse"
          style="margin-left: 16px"
          v-b-toggle.filter
          variant="primary"
        >
          <span class="fe fe-plus-square pt-1" style="font-size: 17px"></span>
        </b-button> -->
        <router-link to="/admin/festival" class="btn btn-outline-info">
          <span class="fa fa-arrow-left"></span>
        </router-link>

      </div>
      <div style="min-height:500px;" class="card-body">
        <!-- <b-collapse id="filter" class="mt-2 mx-5">
          <div>
            <b-form-group label="انتخاب کاربر">
              <multiselect
                v-model="selectedUser"
                @search-change="getUser"
                placeholder="کاربر"
                open-direction="bottom"
                :options="usersList"
                label="label"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <div class="col-md-12">
              <label for="">انتخاب صندلی</label>
              <div class="chairs">
                <button
                  v-for="(chair, index) in chairs"
                  :class="{active:chair.id==selectedChair}"
                  @click="selectedChairFunc(chair.id)"
                  :key="index"
                >
                  {{ chair.chair_number }}
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
              style="margin:auto;margin-bottom: 16px;"
              :disabled="!selectedUser||disabled"
              @click="registerUser()"
              variant="primary"
              class="col-md-6"
            >
              ثبت
            </b-button>
            </div>
          </div>
        </b-collapse> -->

        <div id="header">
          <b-form-group label="بازی ها را انتخاب کنید" class="col-md-6">
            <multiselect
              placeholder="بازی ها"
              v-model="selectedGames"
              open-direction="bottom"
              :options="games"
              label="title"
              track-by="id"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-button @click="filterUsers" :disabled="disabledButton" variant="primary"> فیلتر سازی </b-button>
        </div>
        <Loading v-if="!users" />
        
        <div v-else style="overflow: auto">

          <b-table hover bordered :items="users" :fields="fields">
            <template v-slot:cell(fullName)="data">
              {{
                data.item.first_name
                  ? data.item.first_name + " " + data.item.last_name
                  : ""
              }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import registerUsersImpl from "@@/core/pages/festival/festival/registerUsers/registerUsersImpl";
export default {
  extends: registerUsersImpl,
};
</script>
<style>
.chairs button {
  width: 50px;
  height: 50px;
  background-color: #e3e3e3;
  border-radius: 4px;
  text-align: center;
  line-height: 31px;
  font-size: 18px;
}
.chairs {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 24px;
}
.chairs button.active {
  background-color: aqua;
}
div#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px;
}
</style>
