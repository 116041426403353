<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-xl-4 col-md-12">
            <b-form-group label="رویداد :">
           
              <multiselect
                  @search-change="getEvent"
                  v-model="selectedEvent"
                  placeholder="انتخاب رویداد"
                  open-direction="bottom"
                  :options="events"
                  label="title"
                  track-by="id"
                  :searchable="true"
                  :multiple="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
              >
                <template slot="noOptions">
                  جستجو کنید
                </template>
                <template slot="noResult">
                  <span v-if="isRequesting" v-text="'در حال جستجو...'" />
                  <span v-else v-text="'موردی یافت نشد'"></span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-xl-4 col-md-12">
            <b-form-group>
              <label for="name"> عنوان <i class="text-danger">*</i></label>
              <b-form-input
                v-model="gallery.title"
                name="title"
                type="text"
                placeholder="عنوان"
              />
            </b-form-group>
          </div>
          <div class="col-xl-4 col-md-12">
            <b-form-group :label="`تصویر اصلی`">
              <b-form-file
                v-model="gallery.main_image"
                name="main_image"
                type="text"
                placeholder="تصویر"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row rowgallery" v-for="(row, index) in rows" :key="index">
          <b-form-group :label="`تصویر شماره ${index + 1}`">
            <b-form-file
              v-model="row.main_image"
              name="main_image"
              type="text"
              placeholder="تصویر"
            />
          </b-form-group>
          <b-form-group label="انتخاب کاربرها ">
            <multiselect
              @search-change="getUser"
              v-model="row.users"
              placeholder="کاربرها"
              open-direction="bottom"
              :options="usersList"
              label="label"
              track-by="id"
              :searchable="true"
              :multiple="true"
              :close-on-select="true"
              :show-labels="false"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-button variant="danger" @click="trashRow(row)">
            <i class="fa fa-trash"></i>
          </b-button>
        </div>
        <b-button @click="addRow()"><i class="fa fa-plus"></i></b-button>
      </div>
    </div>
    <div class="text-center mt-5">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        {{ mode == "edit" ? " ویرایش گالری" : " ثبت گالری" }}
      </button>
    </div>
  </form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import galleryFormImpl from "@@/core/components/gallery/galleryForm/galleryFormImpl";
export default {
  components: {
    Treeselect,
  },
  extends: galleryFormImpl,
};
</script>

<style scoped>
.row.rowgallery .multiselect {
  min-width: 250px !important;
}
.row.rowgallery {
  gap: 24px;
  align-items: center;
}
</style>
