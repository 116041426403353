import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BFormCheckbox,
  BForm,
  BTab,
  BTabs,
} from "bootstrap-vue";
import configProvider from "@@/core/configProvider";

export default {
  components: {
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BFormCheckbox,
    BForm,
  },
  props: {
    mode: {
      default: "create",
      type: String,
    },
    url: String,
    user: Object,
    redirect: {
      default: true,
      type: Boolean,
    },
    isDisabled: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      disabled: false,
      items: [
        {
          value: "male",
          name: "مرد",
        },
        {
          value: "female",
          name: "زن",
        },
      ],
      btnDisabled: false,
      userRoles: null,
      selecteduserRole: null,
    };
  },
  watch: {
    user() {
      const i = setInterval(() => {
        if (configProvider.get("user.hasRole") && this.userRoles) {
          this.selecteduserRole = this.userRoles.find(
            (c) => c.id == this.user.role_id
          );
          clearInterval(i);
        }
      }, 100);
    },
  },
  mounted() {
    if (configProvider.get("user.hasRole")) {
      this.loadCreate();
    }
  },
  methods: {
    async loadCreate() {
      const response = await this.$axios.get(
        'admin/widgets?widgets=["create_user"]'
      );
      this.userRoles = response.data.data.widgets.user_roles.map((cR) => {
        cR.value = cR.id;

        return cR;
      });
    },
    setDisabled(val) {
      this.disabled = val;
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;
      try {
          let formData = new FormData(e.target);
        //   for (const key in this.user) {
        //     if(key){}
        //   }
        formData.append(
          "gender",
          this.user.gender ? this.user.gender.value : ""
        );
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        if (this.redirect) {
          this.$router.push("/admin/users");
        } else {
          this.$emit("create", response.data.data.user);
        }
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
