<template>
  <main class="mt-5 admin-slider">
    <span id="add-btn">
      <button @click="newMenu" v-b-modal.menu-modal class="btn btn-success">
        منو جدید
      </button>
    </span>
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">منو</h5>
        <div style="flex: 1"></div>
        <b-button
          v-if="change"
          @click="sortMenu"
          :disabled="disabled"
          variant="info"
        >
          اعمال تغییرات
        </b-button>
      </div>
      <div class="card-body">
        <template v-if="menu_items != null">
          <VueNestable v-model="menu_items" rtl @change="onChange">
            <VueNestableHandle slot-scope="{ item }" :item="item">
              <span style="user-select: none">
                <i class="fa fa-arrows-v" style="font-size: 11px"></i>
                {{ item.id + " - " + item.title }}</span
              >
              <span :id="'edit-btn-' + item.id">
                <a @click="editItem(item)" class="action-btns1 px-2 mr-2">
                  <i
                    class="feather feather-edit text-primary"
                    style="font-size: 14px"
                  ></i>
                </a>
              </span>

              <span :id="'delete-btn-' + item.id">
                <a class="action-btns1 px-2 mr-1" @click="deleteItem(item.id)">
                  <i
                    class="feather feather-trash-2 text-danger"
                    style="font-size: 14px"
                  ></i>
                </a>
              </span>
            </VueNestableHandle>
          </VueNestable>
        </template>
        <Loading v-if="menu_items == null" />
        <div class="alert alert-danger text-right" v-if="menu_items == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </div>
    <menuModal
      @refreshMenu="refreshMenu"
      :mode="mode"
      :linkables="linkables"
      :group="group"
      :menu_item="menu_item"
      :options="options"
      :labelMultiselect="modelLable"
    />
  </main>
</template>
<script>
import MenuImpl from "@@/core/pages/menu/Menu/MenuImpl";
export default {
  extends: MenuImpl,
};
</script>

<style scoped src="@@/core/pages/menu/Menu/MenuStyle.css"></style>
