// مرتب سازی از قیمت کم به زیاد
export function sortVarieties(product) {
    if (!product.varieties) {
        return []
    }
    return [...product.varieties].sort((item1, item2) => {
        return (item1.final_price.amount) - (item2.final_price.amount)
    })
}

export function getImages(product) {
    let varieties = sortVarieties(product)
    if (product.images != '') {
        return product.images
    } else {
        let images = []
        for (let i = 0; i < varieties.length; i++) {
            if (varieties[i].images != '') {
                images = varieties[i].images
                break;
            }
        }
        return images
    }
}


// فیلتر کردن شیپینگ ها بر اساس آدرس
export function setShipping(address, shippings) {
    let shipping = [];
    shippings.forEach((el) => {
        if (el.provinces != "") {
            let tempCities = el.cities.filter(
                (city) => {
                    return city.province_id == address.city.province_id
                }
            );
            if (el.cities != "" && tempCities != "") {
                if (el.cities.find((p) => p.id == address.city_id)) {
                    shipping.push(el);
                }
            } else if (
                el.provinces.filter(
                    (province) => province.id == address.city.province_id
                ) != ""
            ) {
                shipping.push(el);
            }
        } else {
            shipping.push(el);
        }
    });
    return shipping;
}

export function getShippingPrice(address, shipping, amount, quantity, isReserved = false, reservation = null) {
    if (reservation) {
        let totalTotalQuantity = reservation.total_total_quantity + quantity;
        let totalPackets = Math.ceil(Number.parseFloat(totalTotalQuantity - shipping.first_packet_size) / shipping.packet_size);

        return (reservation.shipping_packet_amount + (totalPackets) * reservation.more_packet_price) - reservation.shipping_amount;
    }
    if (!shipping) {
        return 0
    }
    let totalPackets = Math.ceil((Number.parseFloat(quantity) - shipping.first_packet_size)/ shipping.packet_size);
    let additionalPrice = (totalPackets) * shipping.more_packet_price;

    let check = () => {
        if (shipping.provinces != '') {
            let shippingItem;
            shippingItem = shipping.cities.find((city) => {
                return city.id == address.city.id;
            });
            if (shippingItem) {
                return shippingItem.pivot.price;
            }
            shippingItem = shipping.provinces.find((province) => {
                return province.id == address.city.province_id;
            })
            if (shippingItem) {
                return shippingItem.pivot.price
            } else {
                return shipping.default_price;
            }
        } else {
            return shipping.default_price;
        }
    }
    if (shipping.free_threshold) {
        if (shipping.free_threshold >= amount) {
            return check() + additionalPrice
        } else {
            return 0;
        }
    } else {
        return check() + additionalPrice
    }
}

// export function getShippingPrice(address, shipping, amount) {
//     let check = () => {
//         if (shipping) {
//             if (shipping.provinces != '') {
//                 let shippingItem = null
//                 shippingItem = shipping.cities.find((city) => {
//                     return city.id == address.city.id;
//                 });
//                 if (shippingItem) {
//                     return shippingItem.pivot.price;
//                 }
//                 shippingItem = shipping.provinces.find((province) => {
//                     return province.id == address.city.province_id;
//                 })
//                 if (shippingItem) {
//                     return shippingItem.pivot.price
//                 } else {
//                     return shipping.default_price;
//                 }
//             } else {
//                 return shipping.default_price;
//             }
//         } else {
//             return 0
//         }
//     }
//
//     if (shipping) {
//         if (shipping.free_threshold) {
//             if (shipping.free_threshold >= amount) {
//                 return check()
//             } else {
//                 return 0;
//             }
//         } else {
//             return check()
//         }
//     } else {
//         return 0;
//     }
// }


// پیدا کردن قیمت سفارش در لیست فاکتور ها

export function findPriceOrder(list) {
    let amount = null;
    list.forEach(element => {

        if (element.status.toLowerCase() == "success") {
            amount = element.amount;
            return;
        }

    });
    return amount;
}
// دریافت مجموع قیمت براساس قیمت تخفیف و تعداد
export function getSumPrice(price, discount, count) {
    if (discount == null) {
        discount = 0
    }
    return ((price * count) - (discount * count));
}
// پیدا کردن نحوه پرداخت
export function findPayments(type, paymentObject) {
    if (type == "gateway") {
        let output = "درگاه مجازی" + "/" + paymentObject.gateway_label
        return output
    } else {
        return "کیف پول"
    }
}


// دریافت وضعیت استاتوس هر سفارش
export function getOrderStatus(status) {

    if (status.toUpperCase() == "NEW") {
        return "جدید";
    } else if (status.toUpperCase() == "CANCELED") {
        return "لغو شده";
    } else if (status.toUpperCase() == "IN_POROGRESS") {
        return "در دست اقدام";
    } else if (status.toUpperCase() == "DELIVERED") {
        return "تکمیل شده";
    } else if (status.toUpperCase() == "RESERVED") {
        return "رزرو شده";
    } else if (status.toUpperCase() == "WAIT_FOR_PAYMENT") {
        return "در انتظار پرداخت";
    }
}

// دریافت وضعیت استاتوس هر فاکتور
export function getInvoicesStatus(status) {

    if (status.toUpperCase() == "SUCCESS") {
        return "پرداخت شده";
    } else if (status.toUpperCase() == "PENDING") {
        return " در انتظار پرداخت";
    } else if (status.toUpperCase() == "FAILED") {
        return " ناموفق";
    }
}

export function getVarietyTitle(variety) {
    if (variety.color_id != null || variety.attributes != '') {
        let title = ''
        if (variety.color_id != null) {
            title = `رنگ : ${variety.color.name} | `
        }
        if (variety.attributes != '') {
            variety.attributes.forEach(item => {
                title = title + `${item.label} : ${item.pivot.value} | `
            })
        }
        let lastSeperatorIndex = title.lastIndexOf('|')
        title = title.substr(0, lastSeperatorIndex) + title.substr(lastSeperatorIndex + 1, title.lengths);
        return title
    } else {
        return ''
    }
}

export function isPermissionError(error) {
    return error.response.data.errors && error.response.data.errors[0].includes('permissions')
}
