<template>
  <div>
    <b-modal title="ثبت مشتری جدید" hide-footer id="newcustomer">
      <CustomerForm @create="onCustomerCreate" :redirect="false" :is-disabled="false"
                    url="admin/customers" :customer="customer" />
    </b-modal>
    <form @submit.prevent="submit" style="min-height: 500px;">
      <div class="row">
        <b-form-group class="col-md-6">
          <div class="d-flex flex-sm-row flex-column justify-content-between ">
            <label class="pb-0" for=""> مشتری <i class="text-danger">*</i></label>
            <div class="mb-1">
              <template v-if="customerWallet">
                موجودی کیف پول مشتری : {{ customerWallet.balance | price }} تومان
                <button type="button"
                        class="btn btn-light py-1"
                        @click="newWallet()"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                       width="15" height="15"
                       viewBox="0 0 226 226"
                       style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#34495e"><path d="M156.505,13.70125c-3.53125,0.05297 -7.43328,0.82984 -12.28875,2.11875c-13.93078,3.6725 -94.0725,25.84875 -94.0725,25.84875c-0.14125,0.03531 -0.2825,0.08828 -0.42375,0.14125c-12.69484,4.78484 -20.69313,9.28719 -25.56625,15.82c-4.83781,6.4975 -5.91484,14.30156 -5.9325,23.58875c0,0.05297 0,0.08828 0,0.14125v2.825c-0.05297,0.565 -0.14125,1.13 -0.14125,1.695v96.615c0,13.96609 11.45891,25.425 25.425,25.425h138.99c13.96609,0 25.425,-11.45891 25.425,-25.425v-97.32125c0,-14.4075 -12.88906,-26.41375 -27.12,-26.41375h-135.6c-5.06734,0 -9.62266,1.44781 -13.41875,3.81375c3.28406,-4.04328 9.48141,-7.85703 20.76375,-12.1475c0.12359,-0.05297 0.15891,-0.08828 0.2825,-0.14125c0.51203,-0.14125 80.17703,-22.15859 93.64875,-25.7075c4.48469,-1.18297 7.83937,-1.80094 10.17,-1.83625c2.33062,-0.03531 3.42531,0.31781 3.955,0.70625c0.52969,0.38844 0.97109,1.14766 1.4125,2.96625c0.44141,1.81859 0.70625,4.59062 0.70625,8.1925v15.11375h9.04v-15.11375c0,-3.91969 -0.24719,-7.23906 -0.98875,-10.31125c-0.74156,-3.07219 -2.18937,-6.09141 -4.8025,-8.05125c-2.61312,-1.95984 -5.9325,-2.59547 -9.46375,-2.5425zM45.2,67.8h135.6c8.81047,0 18.08,8.7575 18.08,17.37375v97.32125c0,9.07531 -7.30969,16.385 -16.385,16.385h-138.99c-9.09297,0 -16.385,-7.29203 -16.385,-16.385v-89.97625l0.14125,-7.48625c0.26484,-3.07219 1.83625,-7.45094 4.8025,-10.87625c3.23109,-3.74313 7.64516,-6.35625 13.13625,-6.35625zM174.02,122.04c-6.25031,0 -11.3,5.04969 -11.3,11.3c0,6.25031 5.04969,11.3 11.3,11.3c6.23266,0 11.3,-5.04969 11.3,-11.3c0,-6.25031 -5.06734,-11.3 -11.3,-11.3z"></path></g></g></svg>
                </button>
              </template>
              <button style="height: 23px;line-height: 1.2" v-b-modal.newcustomer type="button" title="ثبت مشتری جدید" class="btn btn-success py-1 mr-1">+</button>
            </div>
          </div>
          <CustomerSearch v-model="order.customer" :show-label="false" @input="getCustomer($event)" />
        </b-form-group>
        <b-form-group v-if="order.customer" class="col-md-6">
          <div class="d-flex justify-content-between mb-1">
            <label for=""> آدرس <i class="text-danger">*</i></label>
            <button class="btn btn-info py-0" type="button" @click="newAddress">
              ثبت آدرس جدید
            </button>
          </div>

          <multiselect
              v-model="order.address"
              :allow-empty="false"
              placeholder="انتخاب کنید"
              open-direction="bottom"
              :options="addresses"
              label="label"
              track-by="id"
              :searchable="true"
              :multiple="false"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
              @input="setShipping($event)"
          >
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="order.address" class="col-md-6">
          <label class="pb-1" for="">
            حمل و نقل <i class="text-danger">*</i></label
          >
          <multiselect
              v-model="order.shipping"
              :allow-empty="false"
              placeholder="انتخاب کنید"
              open-direction="bottom"
              :options="addressShippings"
              label="name"
              track-by="id"
              :searchable="true"
              :multiple="false"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
          >
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label class="pb-1" for=""> تخفیف (تومان) </label>
          <b-form-input
              :value="order.discount_amount | priceinput"
              @input="
            (value) => {
              order.discount_amount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          >
          </b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6">
          <label class="pb-1" for=""> توضیحات </label>
          <b-form-textarea
              v-model="order.description"
              name="description"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div>
        <h6 class="font-bold">محصولات <span class="text-danger" v-if="productsSearchMessage" v-text="productsSearchMessage" /></h6>
        <ProductSearch class="row" @message="setSearchMessage" @selectProduct="selectTheProduct"
                       @selectVariety="selectedVariety"  :disabled="disabled" />
        <template v-if="order.varieties != ''">
          <b-table
              class="mt-3 admin-order"
              hover
              bordered
              responsive
              :items="order.varieties"
              :fields="fields"
          >
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(image)="data" class="text-center">
              <div class="order-product-img">
                <router-link
                    :to="'/admin/products/edit/' + data.item.variety.product.id"
                    v-if="data.item.variety"
                >
                  <img
                      class="w-100 h-100"
                      v-if="data.item.variety && data.item.variety.images != ''"
                      :src="data.item.variety.images[0].url"
                      alt=""
                  />
                  <img
                      class="w-100 h-100"
                      v-else-if="
                    data.item.variety.product &&
                      data.item.variety.product.major_image
                  "
                      :src="data.item.variety.product.major_image.url"
                      alt=""
                  />
                </router-link>
              </div>
            </template>
            <template v-slot:cell(title)="data">
            <span style="width:300px">
              <p class="mb-0">
              {{ data.item.variety.product.title }}
            </p>
           شناسه :  {{data.item.variety.id}} | {{ data.item.variety.title }}
            </span>
            </template>
            <template v-slot:cell(quantity)="data">
              <button
                  type="button"
                  class="border border-radius7 px-1 bg-none"
                  @click="decrement(data.item)"
                  :disabled="data.item.quantity == 1 || disabled ? true : false"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-dash"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                  />
                </svg>
              </button>
              <span class="px-2">{{ data.item.quantity }}</span>
              <button
                  type="button"
                  class="border border-radius7 px-1 bg-none"
                  @click="increment(data.item)"
                  :disabled="disabled"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              </button>
              <!-- <b-form-input
              placeholder="تعداد"
              v-model="data.item.quantity"
            ></b-form-input> -->
              <input
                  type="hidden"
                  :name="'varieties[' + data.index + '][id]'"
                  :value="data.item.variety_id"
              />
              <input
                  type="hidden"
                  :name="'varieties[' + data.index + '][quantity]'"
                  :value="data.item.quantity"
              />
            </template>
            <template v-slot:cell(amount)="data">
            <span v-if="data.item.amount != 0">{{
                (data.item.amount + data.item.discount_amount) | price
              }}</span>
              <span v-else>0</span>
            </template>
            <template v-slot:cell(discount_amount)="data">
            <span v-if="data.item.discount_amount">{{
                (data.item.discount_amount) | price
              }}</span>
              <span v-else>ندارد</span>
            </template>
            <template v-slot:cell(flash)="data">
              {{ data.item.variety.product && data.item.variety.product.active_flash != '' ? data.item.variety.product.active_flash[0].title : "ندارد" }}
            </template>
            <template v-slot:cell(total_price)="data">
            <span v-if="data.item.amount != 0">{{
                (data.item.amount * data.item.quantity) | price
              }}</span>
              <span v-else>0</span>
            </template>
            <template v-slot:cell(delete)="data">
              <a class="action-btns1  pt-1 px-2 " @click="deleteItem(data.index)">
                <i class="feather feather-trash-2 text-danger"></i>
              </a>
            </template>
          </b-table>
          <div class="mt-5 col-md-3 mx-auto">
            <div class="d-md-flex justify-content-between">
            <span class=" px-3 py-2 font-bold">
              مجموع قیمت کالا ها:
            </span>
              <span class=" px-3 py-2"> {{ total | price }} تومان </span>
            </div>
            <div class="d-md-flex justify-content-between">
            <span class=" px-3 py-2 font-bold">
              تخفیف :
            </span>
              <span v-if="order.discount_amount" class=" px-3 py-2">
              {{ order.discount_amount | price }} تومان
            </span>
              <span v-else class=" px-3 py-2">
              ندارد
            </span>
            </div>
            <div v-if="order.shipping" class="d-md-flex justify-content-between">
            <span class=" px-3 py-2 font-bold">
              هزینه ارسال :
            </span>
              <span v-if="getShippingPrice != 0" class=" px-3 py-2">
              {{ getShippingPrice | price }} تومان
            </span>
              <span v-else class=" px-3 py-2">
              رایگان
            </span>
            </div>
            <div class="d-md-flex justify-content-between">
            <span class=" px-3 py-2 font-bold">
              پرداخت نهایی :
            </span>
              <span class=" px-3 py-2"> {{ totalPrice | price }} تومان </span>
            </div>
          </div>
        </template>

        <div class="col-md-12 text-center mt-5">
          <template v-if="disabledBtn">
            <div class="text-danger mb-3">
              مبلغ پرداخت نهایی از موجودی کیف پول مشتری بیشتر است.
            </div>
          </template>
          <button :disabled="disabled || disabledBtn ? true : false" type="submit" class="btn btn-success">
            {{ mode == "edit" ? " ویرایش سفارش" : " ثبت سفارش" }}
          </button>
        </div>
      </div>
      <address-modal
          @new-address="addNewAddress"
          :mode="addressMode"
          v-model="address"
          :adminProvinces="provinces"
      ></address-modal>
      <wallet-modal v-model="wallet" @set-new-wallet="setNewWallet"></wallet-modal>
    </form>
  </div>
</template>
<script>
import OrderFormImpl from '@@/core/components/order/OrderForm/OrderFormImpl'
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
import CustomerSearch from "@@/core/components/shared/CustomerSearch/CustomerSearch";
export default {
  components: {CustomerSearch, ProductSearch},
  extends: OrderFormImpl
}
</script>
<style scoped src="@@/core/components/order/OrderForm/OrderFormStyle.css"></style>
