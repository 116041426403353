<template>
  <form @submit.prevent="submit">
    <div class="row">
 
      <div class="col-xl-8 col-md-12">
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">اطلاعات اولیه</h5>
          </div>
          <div class="card-body">
            <b-form-group label="عنوان">
              <b-form-input
                v-model="events.title"
                name="title"
                type="text"
                placeholder="عنوان"
              />
            </b-form-group>
            <b-form-group label="هزینه رزرو صندلی">
              <b-form-input
                v-model="events.chair_price"
                name="chair_price"
                type="text"
                placeholder="قیمت به تومان"
              />
            </b-form-group>
            <b-form-group label="حداکثر گنجایش نفرات">
              <b-form-input
                v-model="max_chairs_capacity"
                type="text"
                placeholder="تعداد صندلی"
              />
            </b-form-group>
            <b-form-group label="انتخاب سالن ">
              <multiselect
                v-model="hall"
                placeholder="سالن"
                open-direction="bottom"
                :options="halls"
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>

            <b-form-group label="تصویر اصلی رویداد">
              <b-form-file
                v-model="events.main_image"
                name="main_image"
                type="text"
                placeholder="تصویر"
              />
            </b-form-group>
            <!-- <b-form-group label="چینش صندلی ها ">
              <multiselect
                v-model="events.chairs_design"
                placeholder="نوع چینش"
                open-direction="bottom"
                :options="chairs_designs"
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group> -->

            <b-form-group label="قوانین">
              <b-form-input
                v-model="events.description"
                name="description"
                type="text"
                placeholder="لینک قوانین"
              />
            </b-form-group>
          </div>
        </div>
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">اطلاعات اصلی</h5>
          </div>
          <div class="card-body">
            <b-form-group label="انتخاب گرداننده">
              
              <multiselect
                v-model="events.event_god_id"
                @search-change="getUser"
                placeholder="داورها"
                open-direction="bottom"
                :options="usersList"
                label="label"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <!-- <b-form-group label="انتخاب داور ها">
              <multiselect
              @search-change="getUser"
                v-model="events.event_referee_id"
                placeholder="داورها"
                open-direction="bottom"
                :options="usersList"
                label="label"
                track-by="id"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group> -->

            <b-form-group label="انتخاب سناریو">
              <multiselect
                v-model="events.scenario_id"
                placeholder="سناریو"
                open-direction="bottom"
                :options="scenario_ids"
                label="title"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-12">
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">اطلاعات مکان</h5>
          </div>
          <div class="card-body">
            <b-form-group label="مکان ایونت">
              <b-form-input
                v-model="events.event_place"
                name="event_place"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="آدرس ایونت">
              <b-form-textarea
                v-model="events.event_address"
                name="event_address"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="تاریخ ایونت">
              <date-picker
                name="event_date"
                type="date"
                v-model="events.event_date"
                format="YYYY-MM-DD"
                display-format="jYYYY-jMM-jDD"
              />
            </b-form-group>
            <b-form-group label="ساعت ایونت">
              <date-picker
                type="time"
                name="event_time"
                v-model="events.event_time"
                format="HH:mm"
                display-format="HH:mm"
              />
            </b-form-group>
          </div>
        </div>
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">تصاویر</h5>
          </div>
          <div class="card-body">
            <b-form-file
              v-model="image1"
              placeholder="تصویره شماره 1"
            ></b-form-file>
            <b-form-file
              v-model="image2"
              placeholder="تصویره شماره 2"
            ></b-form-file>
            <b-form-file
              v-model="image3"
              placeholder="تصویره شماره 3"
            ></b-form-file>
          </div>
        </div>
        <div class="card col-12">
          <div class="card-header border-0">
            <h5 class="card-title">اطلاعات نهایی</h5>
          </div>
          <div class="card-body">
            <b-form-group v-if="!hideEventType" label="نوع رویداد">
              <multiselect
                v-model="events.event_type"
                placeholder="انتخاب نوع "
                open-direction="bottom"
                :options="event_types"
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <b-form-group label="وضعیت رویداد">
              <multiselect
                v-model="events.status"
                placeholder="انتخاب وضعیت "
                open-direction="bottom"
                :options="statuses"
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <div class="text-center mt-5">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-success"
              >
                {{ mode == "edit" ? " ویرایش ایونت" : " ثبت ایونت" }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12">
        <b-form-group label="توضیح">
          <b-form-textarea
            v-model="events.description"
            name="description"
          ></b-form-textarea>
        </b-form-group>
      </div> -->
    </div>
  </form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import eventsFormImpl from "@@/core/components/events/eventsForm/eventsFormImpl";
export default {
  components: {
    Treeselect,
  },
  extends: eventsFormImpl,
};
</script>

<style scoped></style>
