<template>
    <main class="mt-5">
        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold">تنظیمات</h5>
            </div>
            <div class="card-body">
                <SettingForm :settings="settings" :url="url" />
            </div> 
        </div>
    </main>
</template>
<script>
import SettingsImpl from '@@/core/pages/Settings/SettingsImpl'
export default {
    extends: SettingsImpl
}
</script>