


import Back from '@@/core/components/shared/Back/Back'
import tournamentForm from '@@/core/components/tournament/tournamentForm/tournamentForm'

    export default {
        components: {
            Back,
            tournamentForm
        },
        data() {
            return {
                selectDefaultHall:""
            }
        },
        methods: {
            selectDefaultValues(e){
                this.selectDefaultHall=e;
            }
            
        },
       
    }
