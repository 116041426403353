<template>
  <li class="slide">
    <template >
      <router-link @click.native="openSlide" :to="menu.to ? menu.to : '#'" class="side-menu__item"
        :class="{active: activeSlide}" :data-toggle="hasChildren(menu) ? 'slide' : '' ">
        <i class="feather  sidemenu_icon" :class="menu.icon"></i>
        <span class="side-menu__label">{{ menu.title }}</span><i v-if="hasChildren(menu)"
          class="angle fa fa-angle-left"></i>
      </router-link>
      <ul v-if="hasChildren(menu)" class="slide-menu">
        <li v-for="(menuItem, index) in menu.children" :key="index" :class="hasChildren(menuItem) ?  'sub-slide' : ''">
          <template >
            <router-link :to="menuItem.to ? menuItem.to : '#'" :class="{active: menuItem.to == $route.path,
                     'sub-side-menu__item': hasChildren(menuItem), 'slide-item': !hasChildren(menuItem)}"
              :data-toggle="hasChildren(menuItem) ? 'sub-slide' : ''" @click.native="openSubSlide"><span
                :class="hasChildren(menuItem) ? 'sub-side-menu__label' : '' ">{{ menuItem.title }}</span>
              <i v-if="hasChildren(menuItem)" class="sub-angle fa fa-angle-left"></i>
            </router-link>
            <ul v-if="hasChildren(menuItem)" class="sub-slide-menu">
              <li v-for="(item, index) in menuItem.children" :key="index">
                <template >
                  <router-link :to="item.to ? item.to : '#'" class="sub-slide-item">{{ item.title }}</router-link>
                </template>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </template>
  </li>
</template>
<script>
 import MenuItemImpl from '@@/core/components/sidebar/MenuItem/MenuItemImpl'
 export default {
   extends: MenuItemImpl
 }
</script>