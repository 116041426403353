const rules = {
  "admin/colors": {
    both: {
      rules: {
        code: ['color', 'label:کد رنگ']
      }
    }
  },
  "admin/brands": {
    both: {
      rules: {
        description: ['textarea']
      }
    }
  },
  "admin/cities": {
    both: {
      rules: {
        province_id: ['required', 'select:provinces', 'select_label:name']
      }
    }
  },
  "admin/posts": {
    both: {
      rules: {
        post_category_id: ["required", 'select:postCategories', 'select_label:name'],
        summary: ['required', 'textarea'],
        body: ['required', 'editor'],
        status: ['custom_select:draft,pending,published,unpublished'],
        meta_description: ['nullable', 'textarea']
      }
    }
  },
  "admin/f_a_qs": {
    both: {
      rules: {
        question: ['required', 'textarea'],
        answer: ['required', 'textarea'],
      }
    }
  },
  "admin/admin": {
    both: {
      rules: {
        role: ['required', 'select:roles', 'select_label:name'],
      }
    },
    create:{
      rules: {
        password: ['required', 'password']
      }
    },
    edit:{
      rules: {
        password: ['nullable', 'password']
      }
    },
  },
  "admin/positions": {
    both: {
      rules: {
        status: ['required', 'boolean'],
        description: ['required', 'textarea'],
      }
    },
  },
  "admin/withdraws": {
    both: {
      rules: {
        amount: 'nullable|string|disabled',
        status: ['custom_select:pending,paid,canceled'],
      }
    }
  }
}

export default rules;

// name example: "admin/cities.store.name"
export function getRule(name) {
  if (typeof name !== 'string' || name === '') {
    return null;
  }
  const parts = name.split('.')
  if (parts.length !== 3 || !rules[parts[0]]) {
    return null;
  }
  const urlRules = rules[parts[0]];
  let output = null;
  ['create', 'edit', 'both'].forEach((i) => {
    if (parts[1] === i || i === 'both') {
      if (urlRules[i] && urlRules[i]['rules'] && urlRules[i]['rules'][parts[2]]) {
        output = urlRules[i]['rules'][parts[2]];
      }
    }
  })

  return output;
}
