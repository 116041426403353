import Input from "@@/core/libraries/form/components/Input";
import inputClass from '@@/core/libraries/form/input'
import {getRule} from "@@/core/libraries/form/customRules";
import crudMixin from "@@/core/mixins/crudMixin";

export default {
  components: {
    Input
  },
  mixins: [crudMixin],
  props: {
    createRules: Object,
    editRules: Object,
    customCreateRules: {
      default: () => {},
      type: Object
    },
    customEditRules: {
      default: () => {},
      type: Object
    },
    url: String,
    mode: {
      default: 'create',
      type: String
    },
    editModel: Object,
    selects: Object
  },
  watch: {
    storeRules: {
      immediate: true,
      handler() {
        this.setRules();
        this.renderInputsWithInterval()
      }
    },
    editRules: {
      immediate: true,
      handler() {
        this.setRules();
        this.renderInputsWithInterval()
      }
    },
    mode: {
      immediate: true,
      handler() {
        this.renderInputsWithInterval()
      }
    },
    editModel: {
      immediate: true,
      handler() {
        this.renderInputsWithInterval()
      }
    },
  },
  data() {
    return {
      inputs: [],
      finalCreateRules: {},
      finalEditRules: {},
      renderInputsTimeout: null,
      disabled: false,
      axiosCancelSource: null
    }
  },
  created() {

  },
  methods: {
    changeValue(name, value) {
      this.inputs = this.inputs.map(input => {
        if (name == input.name) {
          input.value = value;
        }

        return value;
      })
    },
    setRules() {
      ['create', 'edit'].forEach(mode => {
        const modeCapital = this.$root.capitalizeFirstLetter(mode);
        for (const key in this[mode + 'Rules']) {
          let customRule = getRule(this.url + '.' + mode + '.' + key);
          if (this[`custom${modeCapital}Rules`] && typeof this[`custom${modeCapital}Rules`][key] !== 'undefined') {
            this[`final${modeCapital}Rules`][key] = this[`custom${modeCapital}Rules`][key];
          } else if (customRule) {
            this[`final${modeCapital}Rules`][key] = customRule;
          } else {
            this[`final${modeCapital}Rules`][key] = this[`${mode}Rules`][key];
          }
        }
      })
    },
    renderInputsWithInterval() {
      if (this.renderInputsTimeout) {
        clearTimeout(this.renderInputsTimeout)
      }
      this.renderInputsTimeout = setTimeout(() => {
        this.renderInputs()
      })
    },
    renderInputs() {
      this.inputs = []
      if (this.mode === 'create') {
        for (const key in this.finalCreateRules) {
          let input = new inputClass(key, this.finalCreateRules[key], {selects: this.selects});
          if (this.mode)
          if (input.isValid()) {
            this.inputs.push(input);
          }
        }
      } else {
        for (const key in this.finalEditRules) {
          let input;
          if (this.mode === 'edit') {
            input = new inputClass(key, this.finalEditRules[key], {value: this.editModel[key], selects: this.selects});
          } else {
            input = new inputClass(key, this.finalCreateRules[key], {selects: this.selects});
          }
          if (input.isValid()) {
            this.inputs.push(input);
          }
        }
      }
    },
    async submit(e) {
      e.preventDefault()
      this.disabled = true
      const formData = new FormData(this.$refs.form)
      formData.set('_method', this.mode === 'create' ? 'post' : 'put');
      this.inputs.forEach((inp) => {
        if (inp.type === 'editor') {
          formData.set(inp.name, window.CKEDITOR.instances[inp.id].getData())
        }
      })
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.post(this.getUrl(), formData, {cancelToken: this.axiosCancelSource.token});
        this.$root.notify(response, 'success')
        if (this.mode == 'create') {
          this.$emit('create', response.data)
          this.inputs.forEach(input => {
            input.resetValue()
          })
        } else {
          this.$emit('edit', response.data, this.editModel._index)
        }
        if (this.modalName) {
          this.$root.$emit('bv::hide::modal', 'modal-' + this.modalName)
        } else {
          if (this.$parent.back) {
            this.$parent.back()
          }
        }
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
    getUrl() {
      if (this.mode === 'create') {
        return this.url
      } else {
        return this.url + '/' + this.editModel.id
      }
    }
  }
}
