import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  name: "tournamentsForm",
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
  },
  props: {
    selectDefaultHall: String,
  },
  data() {
    return {
      image1: "",
      image2: "",
      image3: "",
      lastQ: "",
      usersList: [],
      tournaments: {
        title: "",
        description: "",
        main_image: "",
        max_capacity:0,
        price: "",
        start_date: null,
        status: "registering",
      },
      main_image: "",
      mode: "",
      url: "/admin/tournaments",
      disabled: false,
    };
  },
  mounted() {
    this.mode = this.$route.params.id ? "edit" : "create";
    this.url =
      this.mode === "edit" ? this.url + "/" + this.$route.params.id : this.url;
    if (this.mode === "edit") {
      this.$axios.get(this.url).then((res) => {
        let eg = res.data.data.eventGame;
        eg.event_type = this.event_types.find((et) => et.id == eg.event_type);
        eg.status = this.statuses.find((e) => e.id == eg.status);
        eg.chairs_design = this.chairs_designs.find(
          (e) => e.id == eg.chairs_design
        );
        eg.scenario_id = eg.scenario;
        this.tournaments = eg;
      });
    }
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      this.disabled = true;

      try {
        let formData = new FormData(e.target);
        formData.append("start_date", this.tournaments.start_date);
        formData.append(`images[0]`, this.image1);
        formData.append(`images[1]`, this.image2);
        formData.append(`images[2]`, this.image2);
        if (this.mode == "edit") {
          formData.append("_method", "PATCH");
        }
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, "success");
        this.$router.push("/admin/tournaments");
      } catch (error) {
        if (error.flag) {
          this.$notify({
            group: "error",
            type: "error",
            type: "خطا",
            text: error.message,
          });
        } else {
          this.$root.notify(error);
        }
      } finally {
        this.disabled = false;
      }
    },
  },
};
