<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">اطلاعات کاربر</h5>
        <div style="flex: 1"></div>
        <Back />
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">نام :</h6>
            <h6 class="pr-1">{{ user.first_name }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">نام خانوادگی :</h6>
            <h6 class="pr-1">{{ user.last_name }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">شماره موبایل :</h6>
            <h6 class="pr-1">{{ user.mobile }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">ایمیل :</h6>
            <h6 class="pr-1">{{ user.email }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">جنسیت :</h6>
            <h6 class="pr-1">
              {{
                user.gender == "female"
                  ? "زن"
                  : user.gender == "male"
                  ? "مرد"
                  : ""
              }}
            </h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">استدلال :</h6>
            <h6 class="pr-1">{{ user.reasoning }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">تارگت صحیح :</h6>
            <h6 class="pr-1">{{ user.correct_target }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">تشخیص صحیح :</h6>
            <h6 class="pr-1">{{ user.correct_diagnosis }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">تاثیر گذاری :</h6>
            <h6 class="pr-1">{{ user.influence }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">سناریو  :</h6>
            <h6 class="pr-1">{{ user.best_scenario }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">فریب :</h6>
            <h6 class="pr-1">{{ user.deception }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">بازی مافیایی :</h6>
            <h6 class="pr-1">{{ user.play_on_mafia }}</h6>
          </div>
          <div class="col-md-4 d-flex">
            <h6 class="font-bold">بازی شهروندی :</h6>
            <h6 class="pr-1">{{ user.play_on_city }}</h6>
          </div>
        </div>

        <!-- <div class="mt-5">
            <button class="btn btn-info py-0 mb-1" type="button" @click="newAddress">
            ثبت آدرس جدید
          </button>
          <h6 class="bg-light text-center rounded py-2 font-bold">
            آدرس های کاربر
          </h6>
          <b-table  v-if="user.addresses"
            hover
            bordered
            responsive
            :items="user.addresses"
            :fields="fields"
          >
            <template v-slot:cell(address)="data">
              <p>
                {{
                  data.item.city && data.item.city.province
                    ? data.item.city.province.name
                    : ""
                }}
                -
                {{ data.item.city ? data.item.city.name : "" }}
                -
                {{ data.item.address }}
                - کد پستی : {{ data.item.postal_code }}
              </p>
            </template>
            <template v-slot:cell(user)="data">
              {{ data.item.first_name }} {{ data.item.last_name }} -
              {{ data.item.mobile }}
            </template>
            <template v-slot:cell(edit)="data">
              <a
                :disabled="disabled"
                @click="editAddress(data.index)"
                class="action-btns1  pt-1 px-2"
              >
                <i class="feather feather-edit  text-primary"></i>
              </a>
            </template>
            <template v-slot:cell(delete)="data">
              <a
                class="action-btns1  pt-1 px-2 "
                @click="deleteItem(data.item.id)"
              >
                <i class="feather feather-trash-2 text-danger"></i>
              </a>
            </template>
          </b-table>
        </div>
        <div class="mt-5">
          <h6 class="bg-light text-center rounded py-2 font-bold">
            تراکنش های کیف پول
          </h6>
          <b-table  v-if="items.data"
            hover
            bordered
            responsive
            :items="items.data"
            :fields="transactionsFields"
          >
          <template v-slot:cell(amount)="data">
            {{data.item.amount | price}}
          </template>
          <template v-slot:cell(type)="data">
            <span class="badge badge-success" v-if="data.item.type == 'deposit'">افزایش کیف پول</span>
            <span class="badge badge-danger" v-else-if="data.item.type == 'withdraw'">برداشت از کیف پول</span>
          </template>
          <template v-slot:cell(tracking_code)="data">
            <span v-if="data.item.deposit && data.item.deposit.active_payment">
              {{ data.item.deposit.active_payment.tracking_code }}
            </span>
            <span v-else>
              –
            </span>
          </template>
          <template v-slot:cell(confirmed)="data">
            <span class="badge badge-success" v-if="data.item.confirmed">موفقیت آمیز</span>
            <span class="badge badge-danger" v-else>ناموفق</span>
          </template>
          <template v-slot:cell(description)="data">
            {{data.item.meta ? data.item.meta.description : ''}}
          </template>
            <template v-slot:cell(created_at)="data">
              {{data.item.created_at | persianDate}}
            </template>
          </b-table>
          <pagination v-if="items.data" :disabled="disabled" :limit="2" :data="items"
            @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div> -->
      </div>

      <address-modal
        @new-address="addNewAddress"
        :mode="addressMode"
        v-model="address"
        :adminProvinces="provinces"
      ></address-modal>
    </div>
  </main>
</template>
<script>
import usersShowImpl from "@@/core/pages/users/usersShow/usersShowImpl";
export default {
  extends: usersShowImpl,
};
</script>
