import Back from '@@/core/components/shared/Back/Back'
import checkListForm from '@@/core/components/checkList/checkListForm/checkListForm'

    export default {
        components: {
            Back,
            checkListForm
        },
       
    }
