<template>
  <div class="card mt-3">
    <div class="card-header border-0 d-flex justify-content-between">
      <h5 class="card-title">انتشار</h5>
      <button @click="submit" :disabled="disabled" class="btn btn-primary submit-btn">ثبت</button>
    </div>
    <div class="card-body">
      <InputGroup label-padding="0px" col1="3" col2="9" label="وضعیت">
        <treeselect
            :multiple="false"
            :options="statusOptions"
            noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
            placeholder="انتخاب وضعیت"
            v-model="realValue.status"
        ></treeselect>
      </InputGroup>
      <InputGroup @label-click="realValue.scheduling = !realValue.scheduling" label-padding="0px" col1="5" col2="7" label="زمان بندی کردن انتشار">
        <b-form-checkbox v-model="realValue.scheduling" :unchecked-value="false" />
      </InputGroup>
      <InputGroup v-if="realValue.scheduling && realValue.status !== 'published'" label-padding="0px" col1="3" col2="9" label="زمان انتشار">
        <date-picker type="datetime" v-model="realValue.publishedAt"  format="YYYY/MM/DD HH:mm"
                     display-format="jYYYY/jMM/jDD HH:mm" color="#3366ff" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductPublishingImpl from '@@/core/components/product/small-cards/ProductPublishing/ProductPublishingImpl'
export default {
  extends: ProductPublishingImpl
}
</script>

<style scoped src="@@/core/components/product/small-cards/ProductPublishing/ProductPublishingStyle.css"></style>
