<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link to="/admin/tournaments/create" class="btn btn-success"
        >تورنومنت جدید</router-link
      >
    </span>

    <div class="card mt-3">
      
      <div class="card-header border-0">
        <h5 class="card-title font-bold">تورنومنت ها</h5>
        <div style="flex: 1"></div>
        <!-- <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'">
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button> -->
      </div>
      <!-- <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-1">
                <b-form-input :disabled="disabled" placeholder="شناسه" v-model="filter.id">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4">
                <b-form-input :disabled="disabled" placeholder="عنوان" v-model="filter.title">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-3">
                <multiselect v-if="! $root.isEmptyObject(widgetData)" placeholder="دسته بندی" v-model="filter.category_id"
                  open-direction="bottom" :options="widgetData.categories" label="title" track-by="id" :searchable="true"
                  :close-on-select="true" :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-2">
                <multiselect placeholder="وضعیت" v-model="filter.status" open-direction="bottom"
                  :options="statusOptions" label="name" track-by="value" :searchable="true" :close-on-select="true"
                  :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-2">
                <multiselect placeholder="وضعیت تایید" v-model="filter.approved_at" open-direction="bottom"
                  :options="approveOptions" label="name" track-by="value" :searchable="true" :close-on-select="true"
                  :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="text-center mt-6">
              <button :disabled="disabled" type="submit" class="btn btn-primary px-5">جستجو</button>
              <button :disabled="disabled" @click="resetFilter" class="btn btn-warning px-5 mr-3">برداشتن فیلتر</button>
              <button :disabled="disabled" @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3">بستن</button>
            </div>
          </form>
        </b-card>
      </b-collapse> -->
      <div class="card-body">
        <div style="overflow: auto">
          <b-table hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(start_date)="data">
              {{ new Date(data.item.start_date).toLocaleDateString("fa")  }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  :to="'/admin/events/edit/' + data.item.id"
                  class="action-btns1 pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit text-primary"></i>
                </router-link>
              </span>
            </template>
            <template v-slot:cell(delete)="data">

              <span :id="'delete-btn-' + data.item.id">
                <a
                  class="action-btns1 pt-1 px-2"
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">موردی یافت نشد</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import tournamentsImpl from "@@/core/pages/tournaments/tournaments/tournamentsImpl";
export default {
  extends: tournamentsImpl,
};
</script>

