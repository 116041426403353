export default {
    props: ['menu'],
    methods: {
      hasChildren(item) {
        return typeof item.children != 'undefined' && item.children != '';
      },
      goTo(to) {
        this.$router.push(to)
      },
      openSlide(e) {
        let $this = this.$(e.currentTarget);
        let checkElement = $this.next();
        let animationSpeed = 300,
          slideMenuSelector = '.slide-menu';
        if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
          checkElement.slideUp(animationSpeed, function () {
            checkElement.removeClass('open');
          });
          checkElement.parent("li").removeClass("is-expanded");
        } else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
          let parent = $this.parents('ul').first();
          let ul = parent.find('ul:visible').slideUp(animationSpeed);
          ul.removeClass('open');
          let parent_li = $this.parent("li");
          checkElement.slideDown(animationSpeed, function () {
            checkElement.addClass('open');
            parent.find('li.is-expanded').removeClass('is-expanded');
            parent_li.addClass('is-expanded');
          });
        }
        if (checkElement.is(slideMenuSelector)) {
          e.preventDefault();
        }
      },
      openSubSlide(e) {
        let $this = this.$(e.currentTarget);
        let checkElement = $this.next();
        let animationSpeed = 300,
          slideMenuSelector = '.sub-slide-menu';
        if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
          checkElement.slideUp(animationSpeed, function () {
            checkElement.removeClass('open');
          });
          checkElement.parent("li").removeClass("is-expanded");
        } else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
          let parent = $this.parents('ul').first();
          let ul = parent.find('ul:visible').slideUp(animationSpeed);
          ul.removeClass('open');
          let parent_li = $this.parent("li");
          checkElement.slideDown(animationSpeed, function () {
            checkElement.addClass('open');
            parent.find('li.is-expanded').removeClass('is-expanded');
            parent_li.addClass('is-expanded');
          });
        }
        if (checkElement.is(slideMenuSelector)) {
          e.preventDefault();
        }
      }
    },
    computed: {
      activeSlide() {
        if (this.menu.to == this.$route.path) {
          return true;
        }
        let active = false;
        const path = this.$route.path;
        if (this.menu.children) {
          this.menu.children.forEach((subMenu) => {
            if (subMenu.to == path) {
              active = true;
            } else if (subMenu.children) {
              subMenu.children.forEach(subSubMenu => {
                if (subSubMenu.to == path) {
                  active = true;
                }
              })
            }
          })
        }
        return active;
      }
    },
    mounted() {}
  }
