import {
    ValidationObserver,
    ValidationProvider
} from "vee-validate";
import adminAssets from '@@/core/mixins/adminAssets'

export default {
    name: 'Login',
    mixins: [],
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            sendingRequest: false,
        }
    },
    methods: {
        async submitLogin(e) {
            try {
                this.sendingRequest = true
                let formData = new FormData(e.target)
                const response = await this.$axios.post('/admin/login', formData)
                this.$root.setCookie('adminAuthorization', `Bearer ${response.data.data.data.access_token}`)
                this.$axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.data.access_token}`
                this.$root.notify(response.data.message, 'success')
                this.$router.push("/admin");

            } catch (error) {
                console.error(error);
                this.$root.notify(error)
            } finally {
                this.sendingRequest = false
            }
        }
    }
}
