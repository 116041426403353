import Loading from '@@/core/components/shared/Loading/Loading'

export default {
  components: {Loading},
  data() {
    return {
      url: 'admin/menu_items/groups',
      groups: null,
      groupLabels: []
    }
  },
  created() {
    this.loadGroup()
  },
  methods: {
    async loadGroup() {
      const response = await this.$axios.get(this.url)

      this.groups = response.data.data.groups
      this.groupLabels = response.data.data.group_labels
    },
  }
}
