import {BFormGroup, BFormInput, BFormTextarea, BTable} from "bootstrap-vue";

export default  {
  name: "ProductSearch",
  props: {
    fullWidth: Boolean,
    disabled: Boolean,
    showLabel: {
      default: true,
      type: Boolean
    },
    productOnly: {
      default: false,
      type: Boolean,
    },
    selectable: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    BFormGroup, BFormInput, BFormTextarea, BTable
  },
  data() {
    return {
      selectedVariety: null,
      varieties: [],
      productSelect: null,
      isRequestingProducts: false,
      products: [],
      productsSearchMessage: null,
      showVarieties: false,
    }
  },
  watch: {
    productSelect(product) {
      return this.$emit('selectProduct', product);
    },
    productsSearchMessage() {
      this.$emit('message', this.productsSearchMessage)
    },
    products(products) {
      return products.map((product) => {
        product.label = "شناسه : " + product.id + ' | ' +
            product.title + " | موجودی کل : " + product.total_quantity;
        return product;
      });
    },
    varieties(varieties) {
      varieties.forEach((v) => {
        if (v.attributes.length == 1) {
          v.title =
              (v.color != null ? v.color.name + " - " : "") +
              v.attributes[0].pivot.value;
        } else if (v.attributes.length > 1) {
          let attr = "";
          v.attributes.forEach((a) => {
            attr = attr + "-" + a.pivot.value;
          });
          v.title = (v.color != null ? v.color.name : "") + attr;
        } else if (v.attributes.length == 0 && v.color != null) {
          v.title = v.color.name;
        }
        v.label = "شناسه : " + v.id + ' | ' + v.title + " | موجودی : " + v.quantity;
        return v;
      });
    },

  },
  methods: {
    reset() {
      for (let [index, value] of Object.entries(this.$options.data.apply(this))) {
        this[index] = value
      }
    },
    async getProducts(q) {
      if (this.lastProductQ == q || q == '' || q.length == 1) {
        return;
      }
      this.lastProductQ = q
      if (this.axiosProductsCancelSource) {
        this.axiosProductsCancelSource.cancel()
      }
      this.axiosProductsCancelSource = this.$axios.CancelToken.source();
      try {
        this.isRequestingProducts = true
        const response = await this.$axios.get('admin/products/search', {
          cancelToken: this.axiosProductsCancelSource.token,
          params: {q}
        })
        this.products = response.data.data.products
        let count = response.data.data.products_count
        if (this.products.length !== count) {
          this.productsSearchMessage = count + ' مورد یافت شده. 20 مورد در حال نمایش است.'
        } else {
          this.productsSearchMessage = ''
        }
        this.isRequestingProducts = false
      }catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$root.notify(e)
        }
      }
    },
    setVarieties(item) {
      this.selectedVariety = null
      this.varieties = [];
      this.showVarieties = true;
      if (item.varieties.length > 1) {
        this.varieties = item.varieties;
      } else if (item.varieties.length == 1) {
        if (
            item.varieties[0] &&
            (item.varieties[0].color != null ||
                item.varieties[0].attributes != "")
        ) {
          this.varieties = item.varieties;
        } else {
          this.selectVariety(item.varieties[0])
        }
      }
    },
    selectVariety(v) {
      if (this.selectable) {
        this.selectedVariety = v;
      }
      this.$emit('selectVariety', v)
    }
  }
}
