<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش سناریو" : "سناریو جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <scenarioForm ref="scenarioForm" />
      </div>
    </div>
  </main> 
</template>
<script>
import scenarioCreateEditImpl from "@@/core/pages/scenario/scenarioCreateEdit/scenarioCreateEditImpl";
export default {
  extends: scenarioCreateEditImpl,
};
</script>
