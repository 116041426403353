<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 v-if="mainData" class="card-title font-bold">
          کاربران شرکت کرده در رویداد {{ mainData.title }}
        </h5>
        <div style="flex: 1"></div>
        <b-button
          ref="menuCollapse"
          style="margin-left: 16px"
          v-b-toggle.filter
          variant="primary"
        >
          <span class="fe fe-plus-square pt-1" style="font-size: 17px"></span>
        </b-button>
        <Back />
      </div>
      <div class="card-body">
        <b-collapse id="filter" class="mt-2 mx-5">
          <div>
            <b-form-group label="انتخاب کاربر">
              <multiselect
                v-model="selectedUser"
                @search-change="getUser"
                placeholder="کاربر"
                open-direction="bottom"
                :options="usersList"
                label="label"
                track-by="id"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <template slot="noOptions"> موردی یافت نشد </template>
                <template slot="noResult"> موردی یافت نشد </template>
              </multiselect>
            </b-form-group>
            <div class="col-md-12">
              <label for="">انتخاب صندلی</label>
              <div class="chairs">
                <button
                  v-for="(chair, index) in chairs"
                  :class="{active:chair.id==selectedChair}"
                  @click="selectedChairFunc(chair.id)"
                  :key="index"
                >
                  {{ chair.chair_number }}
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
              style="margin:auto;margin-bottom: 16px;"
              :disabled="!selectedUser||disabled"
              @click="registerUser()"
              variant="primary"
              class="col-md-6"
            >
              ثبت
            </b-button>
            </div>
          </div>
        </b-collapse>

        <Loading v-if="!mainData" />
        <div v-else style="overflow: auto">
          <b-table
            hover
            bordered
            :items="mainData.reserved_chairs"
            :fields="fields"
          >
            <template v-slot:cell(fullName)="data">
              {{
                data.item.user.first_name
                  ? data.item.user.first_name + " " + data.item.user.last_name
                  : data.item.user.mobile
              }}
            </template>
            <template v-slot:cell(chair_number)="data">
              {{ data.item.chair_number }}
            </template>
            <template v-slot:cell(sum_scores)="data">
              {{ data.item.user.sum_scores }}
            </template>
            <template v-slot:cell(grade)="data">
              {{ data.item.user.grade }}
            </template>
            <template v-slot:cell(count_joined_tournaments)="data">
              {{ data.item.user.count_joined_tournaments }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import registerUsersImpl from "@@/core/pages/events/registerUsers/registerUsersImpl";
export default {
  extends: registerUsersImpl,
};
</script>
<style>
.chairs button {
  width: 50px;
  height: 50px;
  background-color: #e3e3e3;
  border-radius: 4px;
  text-align: center;
  line-height: 31px;
  font-size: 18px;
}
.chairs {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 24px;
}
.chairs button.active {
  background-color: aqua;
}

</style>
