<template>
    <b-modal id="comment-answer" title="پاسخ نظر" hide-footer>
        <form @submit.prevent="commentAnswer()">
            <div class="d-flex">
                <span class="font-bold">نظر : </span>
                <span> {{body}} </span>
            </div>
            <b-form-textarea v-model="answer" class="my-2" rows="5"></b-form-textarea>
            <div class="text-center">
                <button type="submit" :disabled="disabled" class="btn btn-success">
                    ثبت پاسخ
                </button>
            </div>
        </form>
    </b-modal>
</template>
<script>
  import PostCommentAnswerImpl from '@@/core/components/post/PostCommentAnswer/PostCommentAnswerImpl'
  export default {
      extends: PostCommentAnswerImpl
  }
</script>