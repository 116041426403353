import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/libraries/form/FormModal";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormFile,
  BFormInput,
  VBModal,
  VBToggle,
  BModal,
  BForm,
} from "bootstrap-vue";
export default {
  name: "PostCategories",
  components: {
    BFormFile,
    FormModal,
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      disable: false,
      title: "",
      main_image: "",
      selectedId: null,
      url: "admin/games",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },

        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        name: "",
        status: null,
      },
      status_options: [
        {
          name: "فعال",
          value: "1",
        },
        {
          name: "غیرفعال",
          value: "0",
        },
        {
          name: "همه",
          value: "",
        },
      ],
    };
  },

  mounted() {
    this.loadItems();
  },
  watch: {
    "filter.status": function (newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.status = this.status_options.find((item) => {
          return item.value == newVal;
        });
      }
    },
  },
  methods: {
    submitGmes(e) {
      e.preventDefault();
      this.disable = true;
      let fd = new FormData();
      fd.append("title", this.title);
      fd.append("main_image", this.main_image);
      if (this.selectedId) {
        fd.append("_method", "PUT");
      }
      this.$axios
        .post(`admin/games${this.selectedId ? "/" + this.selectedId : ""}`, fd)
        .then((res) => {
          this.title = null;
          this.main_image = null;
          this.mode = "create";
          this.$root.successNotif(res.data.message);
          this.disable = false;
          this.loadItems();
          this.$root.$emit("bv::hide::modal", "modal-games");
        })
        .catch((error) => {
          this.$root.notify(error);
          this.disable = false;
        });
    },

    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: "name",
        search2: this.filter.status ? this.filter.status.value : "",
        searchBy2: "status",
      };
    },
    createItem() {
      this.mode = "create";
      this.selectedId = null;
      this.$root.$emit("bv::show::modal", "modal-games");
    },
    editItem(item) {
      this.mode = "edit";
      this.title = item.title;
      this.selectedId = item.id;
      this.$root.$emit("bv::show::modal", "modal-games");
    },
  },
};
