<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش جشنواره" : "جشنواره جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <b-form class="row" id="festivalForm" @submit.prevent="sendFestival">
          <b-form-group class="col-md-6" label="عنوان را وارد کنید :">
            <b-form-input v-model="festival.title"></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" label="تصویر اصلی :">
            <b-form-file v-model="festival.main_image"></b-form-file>
          </b-form-group>

          <b-form-group class="col-md-6" label="شروع جشنواره :">
            <date-picker
              type="datetime"
              v-model="festival.start_date"
              format="YYYY-MM-DD HH:mm"
              display-format="jYYYY-jMM-jDD HH:mm"
            />
          </b-form-group>
          <b-form-group class="col-md-6" label="پایان جشنواره :">
            <date-picker
              type="datetime"
              v-model="festival.end_date"
              format="YYYY-MM-DD HH:mm"
              display-format="jYYYY-jMM-jDD HH:mm"
            />
          </b-form-group>
          <b-form-group class="col-md-6" label="توضیحات را وارد کنید">
            <BFormTextarea v-model="festival.description"></BFormTextarea>
          </b-form-group>
          <b-form-group label="بازی ها را انتخاب کنید" class="col-md-6">
            <multiselect
              placeholder="بازی ها"
              v-model="selectedGames"
              open-direction="bottom"
              :options="games"
              label="title"
              track-by="id"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
              <template slot="noOptions"> موردی یافت نشد </template>
              <template slot="noResult"> موردی یافت نشد </template>
            </multiselect>
          </b-form-group>
          <b-table
            v-if="selectedGames"
            hover
            bordered
            :items="selectedGames"
            :fields="fields"
          >
            <template v-slot:cell(id)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(title)="data">
              {{ data.item.title }}
              <b-form-input
                style="display: none"
                type="hidden"
                :value="data.item.id"
                readOnly
                :name="`games[${data.index}][id]`"
              ></b-form-input>
            </template>
            <template v-slot:cell(price)="data">
              <b-form-input
                :value="
                  selectedGames[data.index].price
                    ? selectedGames[data.index].price
                    : 0
                "
                :name="`games[${data.index}][price]`"
              ></b-form-input>
            </template>
          </b-table>
          <b-button type="submit" variant="primary" :disabled="disabled">
            {{ mode == "create" ? "ثبت جشنواره" : "ویرایش جشنواره" }}
          </b-button>
        </b-form>
      </div>
    </div>
  </main>
</template>
<script>
import createEditImpl from "@@/core/pages/festival/festival/createEdit/createEditImpl";
export default {
  extends: createEditImpl,
};
</script>
