<template>
  <div class="list-group">
    <div v-for="(log, index) in logs" :key="log.id"
         class="list-group-item d-flex pt-3 pb-3 align-items-center border-0">
      <div class="ml-3 ml-xs-0">
        <div class="calendar-icon icons">
          <div class="date_time"
               :class="{'bg-success-transparent': index%2===1, 'bg-pink-transparent': index%2===0}">
            <span class="date">{{ moment(log.created_at).format('D') }}</span>
            <span class="month">{{ moment(log.created_at).format('HH:mm') }}</span>
          </div>
        </div>
      </div>
      <div class="ml-1">
        <div class="h5 fs-14 mb-1">یک {{ getModelLabel(log.subject_type) }} {{ getEventLabel(log.event) }} شد
        </div>
        <small class="text-muted">{{ log.description.substr(0, 50) }}
          {{ log.description.length > 50 ? '...' : '' }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityLogImpl from "@@/core/components/dashboard/ActivityLog/ActivityLogImpl";

export default {
  extends: ActivityLogImpl
}
</script>

<style scoped>

</style>
