import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "gallery",
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/gallery",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },

        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },

        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        title: "",
        gallery_category_id: null,
        status: null,
      },
      widgets: ["gallery_categories"],
      status_options: [
        {
          name: "پیش نویس",
          value: "draft",
        },
        {
          name: "در انتظار بررسی",
          value: "pending",
        },
        {
          name: "انتشار نیافته",
          value: "unpublished",
        },
        {
          name: "انتشار یافته",
          value: "published",
        },
        {
          name: "همه",
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    "filter.status": function (newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.status = this.status_options.find((item) => {
          return item.value == newVal;
        });
      }
    },
    "filter.gallery_category_id": function (newVal) {
      if (this.widgetData.galleryCategories) {
        if (typeof newVal === "string" || typeof newVal === "number") {
          this.filter.gallery_category_id =
            this.widgetData.galleryCategories.find((item) => {
              return item.id == newVal;
            });
        }
      }
    },
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.title,
        searchBy1: "title",
        search2: this.filter.gallery_category_id
          ? this.filter.gallery_category_id.id
          : "",
        searchBy2: "gallery_category_id",
        search3: this.filter.status ? this.filter.status.value : "",
        searchBy3: "status",
      };
    },
  },
};
