import {getEventLabel, getModelLabel} from '@@/core/libraries/adminFunctions'
import OrderStatus from '@@/core/components/order/OrderStatus/OrderStatus'
import ProductCommentShow from '@@/core/components/product/ProductCommentShow/ProductCommentShow'
import Loading from '@@/core/components/shared/Loading/Loading'
import {BFormRating,BTable} from "bootstrap-vue";
import {isPermissionError} from "@@/core/libraries/functions";

export default {
  name: "Dashboard",
  components: {
    OrderStatus,
    ProductCommentShow,
    Loading,
    BFormRating,BTable
  },
  mixins: [],
  data() {
    return {
      data: null,
      showingProductComment: null,
      notLoading: false,
      today_eventsFields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "remaining_capacity",
          label: "ظرفیت باقی مانده",
        },
        
        {
          key: "event_time",
          label: "زمان برگزاری",
        },
        {
          key: "event_place",
          label: "مکان",
        },
        {
          key: "register",
          label: "ثبت نام",
        },
      ],
    }
  },
  watch: {
    data() {
    }
  },
  created() {
    // if (this.$store.getters['admin/dashboard/getData']) {
    //   this.data = this.$store.getters['admin/dashboard/getData'];
    // } else {
      this.loadData()
    // }
  },
  computed: {},
  methods: {
    getModelLabel, getEventLabel,
    async loadData() {
      try {
        this.data = await this.$store.dispatch('admin/dashboard/fetchData');
      } catch (error) {
        if (!isPermissionError(error)) {
          this.$root.notify(error)
        } else {
          this.notLoading = true
        }
      }
    },
    
    showProductComment(comment) {
      this.showingProductComment = comment;
      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "comment-modal");
      })
    },
    onProductCommentStatusChanged(payload) {
      let id = payload.id;
      let status = payload.status;
      this.data.comments.product_comments = this.data.comments.product_comments.map(comment => {
        if (comment.id == id) {
          comment.status = status;
        }

        return comment;
      })
    },
    getTotalItemsInOrder(order) {
      let total = 0;
      order.items.forEach(item => total += item.quantity)
      return total;
    }
  }
}
