import {BModal, BFormGroup, BFormTextarea, BFormInput} from "bootstrap-vue";

export default {
    components: {
        BModal, BFormGroup, BFormTextarea, BFormInput
    },
    props: {
        comment: Object,
        url: String,
        mode: {
            default: 'parent',
            type: String
        },
    },
    data() {
        return {
            disabled: false,
            statusOptions: [{
                    value: 'pending',
                    name: 'در انتظار بررسی'
                },
                {
                    value: 'unapproved',
                    name: 'تایید نشده'
                },
                {
                    value: 'approved',
                    name: 'تایید شده'
                },
            ]
        }
    },
    methods: {
        async submit(e) {
            e.preventDefault()
            this.disabled = true
            try {
                const response = await this.$axios.post(`admin/comments/${this.comment.id}`, {
                    name: this.$refs.name.$el.value,
                    email: this.$refs.email.$el.value,
                    body: this.$refs.body.$el.value,
                    status: this.comment.status ? this.comment.status.value : '',
                    _method: 'put'
                });
                this.$root.notify(response.data.message, 'success')
                let comment = response.data.data.comment
                comment.children = this.comment.children
                if (this.mode == 'parent') {
                    this.$root.$set(this.$parent._data.items.data, this.comment.index, comment);
                } else {
                    this.$root.$set(this.$parent._data.items.children, this.comment.index, response.data.data
                        .comment);
                }
                this.$root.$emit('bv::hide::modal', 'edit-modal')
            } catch (error) {
                this.$root.notify(error)
            }
            this.disabled = false
        },
    }
}
