import Back from "@@/core/components/shared/Back/Back";
import userForm from "@@/core/components/user/userForm/userForm";

export function userFactory() {
  return {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    national_code: "",
    gender: null,
    cart_number: "",
    birth_date: "",
    newsletter: 0,
    foreign_national: 0,
    reasoning: 0,
    correct_target: 0,
    correct_diagnosis: 0,
    influence: 0,
    best_scenario: 0,
    deception: 0,
    play_on_mafia: 0,
    play_on_city: 0,
  };
}

export default {
  components: {
    Back,
    userForm,
  },
  data() {
    return {
      title: "",
      url: "",
      axiosCancelSource: null,
      user: userFactory(),
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = "admin/users/" + this.$route.params.id;
      this.getUser();
    } else {
      this.$refs.userForm.setDisabled(false);
      this.url = "admin/users";
    }
  },
  methods: {
    async getUser() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(
          `admin/users/${this.$route.params.id}`,
          {
            cancelToken: this.axiosCancelSource.token,
          }
        );
        this.user = response.data.data.user;
        if (this.user.gender == "male") {
          this.user.gender = {
            value: "male",
            name: "مرد",
          };
        } else {
          this.user.gender = {
            value: "female",
            name: "زن",
          };
        }
        this.$refs.userForm.setDisabled(false);
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
  },
};
