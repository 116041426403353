<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">تنظیمات نمایش</h5>
    </div>
    <div class="card-body">
      <InputGroup v-if="$options.configProvider.get('product.hasShowQuantity')" @label-click="realValue.showQuantity = !realValue.showQuantity"
                  label-padding="0px" col1="4" col2="8" label="نمایش موجودی">
        <b-form-checkbox v-model="realValue.showQuantity" :unchecked-value="false" />
      </InputGroup>
      <InputGroup  @label-click="realValue.chargeable = !realValue.chargeable"
                  label-padding="0px" col1="4" col2="8" label="قابل شارژ">
        <b-form-checkbox v-model="realValue.chargeable" :unchecked-value="false" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductShowConfigImpl from '@@/core/components/product/small-cards/ProductShowConfig/ProductShowConfigImpl'
import configProvider from "@@/core/configProvider";

export default {
  extends: ProductShowConfigImpl,
  configProvider
}
</script>

<style scoped>

</style>
