import Back from '@@/core/components/shared/Back/Back';
import galleryForm from '@@/core/components/gallery/galleryForm/galleryForm'
export default {
  name: 'galleryCreateEdit',
  components: {Back, galleryForm},
  data() {
    return {
      data: {},
      axiosCancelSource: null,
      widgetData: {},
      createRules: {},
      editRules: {},
      mode: 'create',
      editModel: {}
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = 'admin/gallery/' + this.$route.params.id
      this.mode = 'edit';
      this.getgallery()
    }
  },
  methods: {
    async getgallery() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url,
            {
              params: {widgets: '["gallery_categories"]'},
              cancelToken: this.axiosCancelSource.token
            });
        this.widgetData = response.data.data.widgets
        this.createRules = response.data.data.store_rules
        this.editRules = response.data.data.update_rules
        this.editModel = response.data.data.gallery
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    },
    async getRules() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url,
            {
              params: {widgets: '["gallery_categories"]'},
              cancelToken: this.axiosCancelSource.token
            });

        this.widgetData = response.data.data.widgets
        this.createRules = response.data.data.store_rules
        this.editRules = response.data.data.update_rules
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    },
    back() {
      this.$refs.back.$el.click();
    }
  }
}
